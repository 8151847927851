import { storyblokEditable } from "@storyblok/react";
import styles from "./TextBox.module.css";
import {
  NODE_LI,
  NODE_PARAGRAPH,
  NODE_UL,
  render,
} from "storyblok-rich-text-react-renderer";
import { motion } from "framer-motion";
import { useWindowSize } from "../../hooks/useWindowSize";
import { sbBlok } from "../../types/sb-types";
import GridWrapper from "../GridWrapper/GridWrapper";
import GridElement from "../GridElement/GridElement";

const TextBox = ({ blok }: sbBlok) => {
  const {
    text_box_layout,
    title_one,
    title_two,
    body_one,
    body_two,
    spacing_top,
    spacing_bottom,
    spacing_default,
  } = blok;
  const isOneColumnLayout = text_box_layout === "one";

  const { isDesktop } = useWindowSize();

  const textBoxVariants = {
    initial: {
      background: isDesktop
        ? "linear-gradient(180deg, #303030 0%, #242424 100%)"
        : "none",
      opacity: isDesktop ? 1 : 0.5,
      transform: isDesktop ? "translate(0, 0)" : "translate(0, 4rem)",
    },
    inView: {
      background: "linear-gradient(180deg, #303030 0%, #242424 100%)",
      opacity: 1,
      transform: "translate(0, 0)",
      transition: {
        ease: "easeInOut",
        duration: 1,
        delay: 0.15,
      },
    },
  };

  return (
    <GridWrapper
      padding_top={spacing_top?.length > 0 && spacing_top[0].option}
      padding_bottom={spacing_bottom?.length > 0 && spacing_bottom[0].option}
      className={`${spacing_default ? styles.gridDefault : ""}`}
      {...storyblokEditable(blok)}
    >
      <GridElement gridLayout={[[1401, 4, 26]]}>
        {isOneColumnLayout && (
          <motion.div
            className={styles.textBoxLayout}
            viewport={{ once: true }}
            variants={textBoxVariants}
            initial="initial"
            whileInView="inView"
          >
            <h2 className={styles.textBoxTitle}>{title_one}</h2>
            {render(body_one, {
              nodeResolvers: {
                [NODE_PARAGRAPH]: (children) => <span>{children}</span>,
                [NODE_UL]: (children) => (
                  <ul className={styles.unorderedList}>{children}</ul>
                ),
                [NODE_LI]: (children) => {
                  return <li>{children}</li>;
                },
              },
            })}
          </motion.div>
        )}
        {!isOneColumnLayout && (
          <div className={styles.twoColumnLayout}>
            <motion.div
              className={styles.textBoxLayout}
              viewport={{ once: true }}
              variants={textBoxVariants}
              initial="initial"
              whileInView="inView"
            >
              <h2 className={styles.textBoxTitle}>{title_one}</h2>
              {render(body_one, {
                nodeResolvers: {
                  [NODE_PARAGRAPH]: (children) => <span>{children}</span>,
                  [NODE_UL]: (children) => (
                    <ul className={styles.unorderedList}>{children}</ul>
                  ),
                  [NODE_LI]: (children) => {
                    return <li>{children}</li>;
                  },
                },
              })}
            </motion.div>
            <motion.div
              className={styles.textBoxLayout}
              viewport={{ once: true }}
              variants={textBoxVariants}
              initial="initial"
              whileInView="inView"
            >
              <h2 className={styles.textBoxTitle}>{title_two}</h2>
              {render(body_two, {
                nodeResolvers: {
                  [NODE_PARAGRAPH]: (children) => <span>{children}</span>,
                  [NODE_UL]: (children) => (
                    <ul className={styles.unorderedList}>{children}</ul>
                  ),
                  [NODE_LI]: (children) => {
                    return <li>{children}</li>;
                  },
                },
              })}
            </motion.div>
          </div>
        )}
      </GridElement>
    </GridWrapper>
  );
};

export default TextBox;
