import { createContext, useReducer, useContext, ReactNode } from "react";
import { signOut } from "next-auth/react";

type Action =
  | { type: "openSignupModal" }
  | { type: "closeSignupModal" }
  | { type: "signupLoading"; payload: boolean }
  | { type: "confirmEmail" }
  | {
      type: "toggleJoinFlowInfoModal";
      payload: {
        isOpen: boolean;
        modalType?: "signup" | "email";
        title?: string;
      };
    };
type Dispatch = (action: Action) => void;
type State = {
  isSignupModalOpen: boolean;
  isSignupLoading: boolean;
  isEmailVerified: boolean;
  joinFlowInfoModal: {
    isOpen: boolean;
    modalType?: "signup" | "email";
    title?: string;
  };
};
type UIProviderProps = {
  children: ReactNode;
};

const UIContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case "signupLoading": {
      return { ...state, isSignupLoading: action.payload };
    }
    case "openSignupModal": {
      return {
        ...state,
        isSignupModalOpen: true,
      };
    }
    case "closeSignupModal": {
      signOut({ redirect: false });
      return { ...state, isSignupModalOpen: false };
    }
    case "confirmEmail": {
      return { ...state, isEmailVerified: true };
    }
    case "toggleJoinFlowInfoModal": {
      if (!action.payload.isOpen) {
        signOut({ redirect: false });
      }
      return {
        ...state,
        joinFlowInfoModal: {
          isOpen: action.payload.isOpen,
          modalType:
            action.payload.isOpen && action.payload.modalType
              ? action.payload.modalType
              : undefined,
          title:
            action.payload.isOpen && action.payload.title
              ? action.payload.title
              : undefined,
        },
      };
    }
    default: {
      throw new Error(`Unhandled action: ${action}`);
    }
  }
}

function UIProvider({ children }: UIProviderProps) {
  const [state, dispatch] = useReducer(uiReducer, {
    isSignupModalOpen: false,
    isSignupLoading: false,
    isEmailVerified: false,
    joinFlowInfoModal: {
      isOpen: false,
    },
  });
  const value = { state, dispatch };
  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
}

function useUI() {
  const context = useContext(UIContext);
  if (context === undefined) {
    throw new Error("useUI must be used within a UIProvider");
  }
  return context;
}

export { UIProvider, useUI };
