import { useState } from "react";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import { sbBlok } from "../../types/sb-types";
import GridElement from "../GridElement";
import GridWrapper from "../GridWrapper";
import styles from "./CourseLibrary.module.css";

interface CourseLibraryProps {
  blok: sbBlok;
}

const CourseLibrary = ({ blok }: CourseLibraryProps) => {
  const [selectedRound, setSelectedRound] = useState(blok.content[0].round_id);
  return (
    <GridWrapper
      padding_top={blok.spacing_top?.length > 0 && blok.spacing_top[0].option}
      padding_bottom={
        blok.spacing_bottom?.length > 0 && blok.spacing_bottom[0].option
      }
    >
      <GridElement>
        <div {...storyblokEditable(blok)}>
          <ul className={styles.courseTabs}>
            {blok.content.map((c: sbBlok) => (
              <li
                key={c._uid}
                onClick={() => setSelectedRound(c.round_id)}
                className={`${styles.courseTab}${
                  c.round_id === selectedRound
                    ? " " + styles.courseTabActive
                    : ""
                }`}
              >
                {c.round_name}
              </li>
            ))}
          </ul>
          <div className={styles.courseWrapper}>
            {blok.content.map((c: sbBlok, i: number) => (
              <div
                key={c._uid}
                className={`${styles.courseOverview}${
                  c.round_id === selectedRound
                    ? " " + styles.courseOverviewActive
                    : ""
                }`}
              >
                <div className={styles.courseMedia}>
                  <Image
                    src={c.image.filename}
                    alt={c.image.alt}
                    layout="fill"
                    objectFit="cover"
                    sizes="(max-width: 960px) 100vw, (min-width: 961) 40vw"
                    priority={i === 0}
                  />
                </div>
                <div className={styles.courseContent}>
                  <h3>{c.round_name}</h3>
                  <h2>{c.round_title}</h2>
                  <p className={styles.courseRoundDate}>{c.round_date}</p>
                  <p className={styles.courseInfo}>{c.round_info}</p>
                  <div className={styles.courseContentListWrapper}>
                    <ul className={styles.courseContentList}>
                      <li>
                        <span>{c.round_info_list[0]?.title}</span>
                        <span>{c.round_info_list[1]?.title}</span>
                      </li>
                      {c.round_info_list[0]?.content.map(
                        (ci: sbBlok, i: number) => (
                          <li key={ci._uid}>
                            <span>{ci.content}</span>
                            <span>
                              {c.round_info_list[1]?.content[i].content}
                            </span>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </GridElement>
    </GridWrapper>
  );
};

export default CourseLibrary;
