import { useEffect } from "react";
import GridWrapper from "../GridWrapper/GridWrapper";
import GridElement from "../GridElement/GridElement";
import { sbBlok } from "../../types/sb-types";

const SocialMediaWidget = ({ blok }: sbBlok) => {
  useEffect(() => {
    // Load Curator.io script
    const script = document.createElement("script");
    script.src =
      "https://cdn.curator.io/published/b35c31d4-b9b9-4ed0-b3e9-09c31ab4e905.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <GridWrapper
      padding_top={blok.spacing_top?.length > 0 && blok.spacing_top[0].option}
      padding_bottom={
        blok.spacing_bottom?.length > 0 && blok.spacing_bottom[0].option
      }
    >
      <GridElement gridLayout={[[1401, 4, 26]]}>
        <div id="curator-feed-mentions-and-tags-layout" />
      </GridElement>
    </GridWrapper>
  );
};

export default SocialMediaWidget;
