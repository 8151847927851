import CountryFlag from "react-country-flag";
import { InfiniteData } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useWindowSize } from "../../hooks/useWindowSize";
import styles from "./Leaderboard.module.css";
import LeaderboardLoading from "./LeaderboardLoading";

export interface GameSummaryPlayerData {
  playerId: string;
  nationality: string;
  playername: string;
  result: {
    pos: number;
    score: number;
  };
}

export interface GameSummaryLeaderboardData {
  data: {
    node: {
      otherLeaderboards: {
        records: { items: [GameSummaryPlayerData] };
      };
    };
  };
}

interface BirdieStreakLeaderboardProps {
  data:
    | undefined
    | InfiniteData<AxiosResponse<GameSummaryLeaderboardData, any>>;
  isLoading: boolean;
  ErrorMessage: () => JSX.Element | null;
}

const BirdieStreakLeaderboard = ({
  data,
  isLoading,
  ErrorMessage,
}: BirdieStreakLeaderboardProps) => {
  const { windowSize } = useWindowSize();
  return (
    <div role="table" className={styles.leaderboardTable}>
      <div role="thead" className={styles.tableHeader}>
        <div role="th" className={styles.tableHeaderCell}>
          Pos
        </div>
        <div role="th" className={styles.tableHeaderCell}>
          <span className={styles.thCellLabel}>Country</span>
        </div>
        <div role="th" className={styles.tableHeaderCell}>
          Player
        </div>
        <div role="th" className={styles.tableHeaderCell}>
          Birdie streak
        </div>
      </div>
      <div role="tbody" className={styles.tableBody}>
        <ErrorMessage />

        {data?.pages?.map(
          (page: { data: GameSummaryLeaderboardData }, x: number) => {

            const itemsLength =
              page?.data?.data?.node?.otherLeaderboards?.records?.items?.length;

            if (x === 0 && !itemsLength) {
              return (
                <h2 key={x} className={styles.infoHeading}>
                  No results found
                </h2>
              );
            }

            return page.data.data.node.otherLeaderboards.records.items.map(
              (player: GameSummaryPlayerData, n: number) => {
                const isPosFirstKind =
                  (x === 0 &&
                    n > 0 &&
                    player?.result?.pos !==
                      page.data.data.node.otherLeaderboards.records.items[n - 1]
                        .result?.pos) ||
                  (x > 0 &&
                    n > 0 &&
                    player?.result?.pos !==
                      page.data.data.node.otherLeaderboards.records.items[n - 1]
                        .result?.pos &&
                    player?.result?.pos !==
                      data.pages[x - 1].data.data.node.otherLeaderboards.records
                        .items[
                        data.pages[x - 1].data.data.node.otherLeaderboards
                          .records.items.length - 1
                      ].result?.pos);
                return (
                  <div
                    role="tr"
                    className={`${styles.tableRowGroup}${
                      isPosFirstKind
                        ? " " + styles.tableRowGroupPostFirstKind
                        : ""
                    }`}
                    key={player.playerId}
                  >
                    <div role="tr" className={styles.tableRow}>
                      <div role="td" className={styles.tableRowCell}>
                        {isPosFirstKind || (x === 0 && n === 0)
                          ? player.result.pos
                          : ""}
                      </div>
                      <div role="td" className={styles.tableRowCell}>
                        {player.nationality && (
                          <CountryFlag
                            countryCode={player.nationality}
                            style={{
                              fontSize:
                                windowSize.width < 960 ? "1.6em" : "2em",
                            }}
                            svg
                          />
                        )}
                      </div>
                      <div role="td" className={styles.tableRowCell}>
                        {player.playername}
                      </div>
                      <div role="td" className={styles.tableRowCell}>
                        <span className={styles.highlight}>
                          {player.result.score}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }
            );
          }
        )}
        {isLoading && <LeaderboardLoading />}
      </div>
    </div>
  );
};

export default BirdieStreakLeaderboard;
