import CountryFlag from "react-country-flag";
import { InfiniteData } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useWindowSize } from "../../hooks/useWindowSize";
import LeaderboardLoading from "./LeaderboardLoading";
import styles from "./Leaderboard.module.css";

interface LogestDrivePlayerData {
  id: string;
  playername: string;
  nationality: string;
  score: {
    pos: number;
    driveDistance: number;
  };
}

interface EmbededGameData {
  data: {
    node: {
      embeddedGameLeaderboard: {
        records: { items: [LogestDrivePlayerData] };
      };
    };
  };
}
interface LongestDriveLeaderboardProps {
  data: undefined | InfiniteData<AxiosResponse<EmbededGameData, any>>;
  isLoading: boolean;
  ErrorMessage: () => JSX.Element | null;
}

const LongestDriveLeaderboard = ({
  data,
  isLoading,
  ErrorMessage,
}: LongestDriveLeaderboardProps) => {
  const { windowSize } = useWindowSize();
  return (
    <div
      role="table"
      className={`${styles.leaderboardTable} ${styles.leaderboardTableLD}`}
    >
      <div role="thead" className={styles.tableHeader}>
        <div role="th" className={styles.tableHeaderCell}>
          Pos
        </div>
        <div role="th" className={styles.tableHeaderCell}>
          <span className={styles.thCellLabel}>Country</span>
        </div>
        <div role="th" className={styles.tableHeaderCell}>
          Player
        </div>
        <div role="th" className={styles.tableHeaderCell}>
          Distance
        </div>
      </div>
      <div role="tbody" className={styles.tableBody}>
        <ErrorMessage />

        {data?.pages?.map((page: { data: EmbededGameData }, x: number) => {
          const itemsLength =
            page.data.data.node.embeddedGameLeaderboard.records.items.length;

          if (x === 0 && !itemsLength) {
            return (
              <h2 key={x} className={styles.infoHeading}>
                No results found
              </h2>
            );
          }

          return page.data.data.node.embeddedGameLeaderboard.records.items.map(
            (player: LogestDrivePlayerData) => {
              return (
                <div role="tr" className={styles.tableRowGroup} key={player.id}>
                  <div role="tr" className={styles.tableRow}>
                    <div role="td" className={styles.tableRowCell}>
                      {player.score.pos}
                    </div>
                    <div role="td" className={styles.tableRowCell}>
                      {player.nationality && (
                        <CountryFlag
                          countryCode={player.nationality}
                          style={{
                            fontSize: windowSize.width < 960 ? "1.6em" : "2em",
                          }}
                          svg
                        />
                      )}
                    </div>
                    <div role="td" className={styles.tableRowCell}>
                      {player.playername}
                    </div>
                    <div role="td" className={styles.tableRowCell}>
                      {(player.score.driveDistance * 1.09361).toFixed(1)} yds
                    </div>
                  </div>
                </div>
              );
            }
          );
        })}
        {isLoading && <LeaderboardLoading />}
      </div>
    </div>
  );
};

export default LongestDriveLeaderboard;
