import { storyblokInit, apiPlugin, getStoryblokApi } from "@storyblok/react";
import App, { AppContext } from "next/app";
import Script from "next/script";
import Head from "next/head";
import dynamic from "next/dynamic";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SessionProvider } from "next-auth/react";

import Page from "../components/Page";
import TextComponent from "../components/TextComponent";
import LeaderboardWrapper from "../components/LeaderboardWrapper";
import Layout from "../components/Layout";
import TextWithMedia from "../components/TextWithMedia";
import HeroHeader from "../components/HeroHeader";
import TeaserRow from "../components/TeaserRow";
import PrizePoolTable from "../components/PrizePoolTable";
import CourseLibrary from "../components/CourseLibrary";
import InfoCarousel from "../components/InfoCarousel";
import ImageComponent from "../components/ImageComponent";
import PrizeList from "../components/PrizeList";
import VideoComponent from "../components/VideoComponent";
import TextWithMediaSmall from "../components/TextWithMediaSmall";
import Countdown from "../components/Countdown";
const Schedule = dynamic(() => import("../components/Schedule"));
const HeroText = dynamic(() => import("../components/HeroText"));
const TeaserList = dynamic(() => import("../components/TeaserList"));
const PrizePool = dynamic(() => import("../components/PrizePool"));
import "../styles/globals.css";
import { useState } from "react";
import Partners from "../components/Partners/Partners";
import Header from "../components/Header/Header";
import BlogPostOverview from "../components/BlogPost/BlogPostsOverview";
import TournamentCardOverview from "../components/TournamentCard/TournamentCardOverview";
import TextBox from "../components/TextBox/TextBox";
import { UIProvider } from "../context/UIContext";
import Faq from "../components/Faq/Faq";
import Tabs from "../components/Tabs/Tabs";
import OOMLeaderboard from "../components/OOMLeaderboard";
import PlayerCarousel from "../components/PlayerCarousel/PlayerCarousel";
import Testimonials from "../components/Testimonials/Testimonials";
import SocialMediaWidget from "../components/SocialMediaWidget/SocialMediaWidget";

storyblokInit({
  accessToken: process.env.SB_ACCESS_TOKEN,
  use: [apiPlugin],
  components: {
    page: Page,
    text_component: TextComponent,
    text_with_media: TextWithMedia,
    hero_header: HeroHeader,
    teaser_row: TeaserRow,
    prize_pool_table: PrizePoolTable,
    course_library: CourseLibrary,
    info_carousel: InfoCarousel,
    image_component: ImageComponent,
    prize_list: PrizeList,
    video_component: VideoComponent,
    text_with_media_small: TextWithMediaSmall,
    countdown: Countdown,
    leaderboards: LeaderboardWrapper,
    oom_leaderboard: OOMLeaderboard,
    partners: Partners,
    header: Header,
    blog_posts_overview: BlogPostOverview,
    tournament_card_overview: TournamentCardOverview,
    text_box: TextBox,
    faq: Faq,
    tabs: Tabs,
    player_carousel: PlayerCarousel,
    teaser_list: TeaserList,
    hero_text: HeroText,
    schedule: Schedule,
    prize_pool: PrizePool,
    testimonials: Testimonials,
    social_media_widget: SocialMediaWidget,
  },
});

declare global {
  interface Window {
    dataLayer: object[];
  }
}

function MyApp({ Component, pageProps: { session, ...pageProps } }: any) {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <>
      {/* Google Tag Manager */}
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5BCBWV8');`,
        }}
      />
      {/* End Google Tag Manager */}
      <Head>
        {!pageProps.isProd && (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <title>
          {pageProps.story?.content?.seo?.title
            ? pageProps.story.content.seo.title
            : "Next Golf Tour"}
        </title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="viewport"
          content="initial-scale=1.0, width=device-width, maximum-scale=1.0, user-scalable=0"
          key="viewport"
        />
        {pageProps.story?.content?.seo?.title && (
          <meta name="title" content={pageProps.story?.content?.seo?.title} />
        )}
        {pageProps.story?.content?.seo?.description && (
          <meta
            name="description"
            content={pageProps.story?.content?.seo?.description}
          />
        )}
        {pageProps.story?.content?.seo?.og_title && (
          <meta
            property="og:title"
            content={pageProps.story?.content?.seo?.og_title}
          />
        )}
        {pageProps.story?.content?.seo?.og_description && (
          <meta
            property="og:description"
            content={pageProps.story?.content?.seo?.og_description}
          />
        )}
        {pageProps.story?.content?.seo?.og_image && (
          <meta
            property="og:image"
            content={pageProps.story?.content?.seo?.og_image}
          />
        )}
        {pageProps.story?.content?.seo?.twitter_title && (
          <meta
            property="twitter:title"
            content={pageProps.story?.content?.seo?.twitter_title}
          />
        )}
        {pageProps.story?.content?.seo?.twitter_description && (
          <meta
            property="twitter:description"
            content={pageProps.story?.content?.seo?.twitter_description}
          />
        )}
        {pageProps.story?.content?.seo?.twitter_image && (
          <meta
            property="twitter:image"
            content={pageProps.story?.content?.seo?.twitter_image}
          />
        )}
      </Head>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <div className={pageProps.theme}>
            <Layout
              navLinks={pageProps.navLinks}
              amateurNavLinks={pageProps.amateurNavLinks}
              theme={pageProps.theme}
            >
              <UIProvider>
                <SessionProvider session={session}>
                  <Component {...pageProps} theme={pageProps.theme} />
                </SessionProvider>
              </UIProvider>
            </Layout>
          </div>
        </Hydrate>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const storyblokApi = getStoryblokApi();
  const sbParams = {
    version: process.env.VERCEL_ENV === "production" ? "published" : "draft",
    resolve_links: "url",
  };
  const { data: homeData } = await storyblokApi.get(
    "cdn/stories/home",
    sbParams
  );

  const { data: amHomeData } = await storyblokApi.get(
    "cdn/stories/amateur",
    sbParams
  );

  const { data: sbLinks } = await storyblokApi.get("cdn/links/", sbParams);
  const navLinks: { slug: string; name: string }[] = [];
  const amateurNavLinks: { slug: string; name: string }[] = [];
  Object.keys(sbLinks.links).forEach((linkKey) => {
    if (sbLinks.links[linkKey].slug.split("/")[0] === "amateur") {
      if (
        sbLinks.links[linkKey].slug === "amateur" ||
        sbLinks.links[linkKey].slug === "amateur/" ||
        sbLinks.links[linkKey].slug === "home" ||
        sbLinks.links[linkKey].slug === "prize-list" ||
        sbLinks.links[linkKey].slug === "welcome-from-ceo" ||
        sbLinks.links[linkKey].slug.includes("news/") ||
        sbLinks.links[linkKey].slug.includes("tournament") ||
        sbLinks.links[linkKey].slug.includes("order-of-merit/") ||
        sbLinks.links[linkKey].slug.includes("signin-oidc")
      ) {
        return;
      }
      amateurNavLinks.push({
        slug: `/${sbLinks.links[linkKey].slug}`,
        name: sbLinks.links[linkKey].name,
      });
    }
    if (sbLinks.links[linkKey].slug.split("/")[0] !== "amateur") {
      if (
        sbLinks.links[linkKey].slug === "amateur" ||
        sbLinks.links[linkKey].slug === "amateur/" ||
        sbLinks.links[linkKey].slug === "home" ||
        sbLinks.links[linkKey].slug === "prize-list" ||
        sbLinks.links[linkKey].slug === "welcome-from-ceo" ||
        sbLinks.links[linkKey].slug.includes("news/") ||
        sbLinks.links[linkKey].slug.includes("tournament") ||
        sbLinks.links[linkKey].slug.includes("order-of-merit/") ||
        sbLinks.links[linkKey].slug.includes("signin-oidc")
      ) {
        return;
      }
      navLinks.push({
        slug: `/${sbLinks.links[linkKey].slug}`,
        name: sbLinks.links[linkKey].name,
      });
    }
  });

  if (homeData.story.content.leaderboard_page_slug) {
    navLinks.splice(2, 0, {
      slug: `/tournament/${homeData.story.content.leaderboard_page_slug}/leaderboard`,
      name: "Leaderboard",
    });
  }

  if (amHomeData.story.content.leaderboard_page_slug) {
    amateurNavLinks.splice(2, 0, {
      slug: `/amateur/tournament/${amHomeData.story.content.leaderboard_page_slug}/leaderboard`,
      name: "Leaderboard",
    });
  }

  if (appProps.pageProps) {
    appProps.pageProps.theme =
      appContext.ctx.pathname.split("/")[1] === "amateur" ? "amateur" : "pro";
    appProps.pageProps.navLinks = navLinks;
    appProps.pageProps.amateurNavLinks = amateurNavLinks;
    appProps.pageProps.isProd = process.env.VERCEL_ENV === "production";
  }

  return { ...appProps };
};

export default MyApp;
