export const LeaderboardTournament = `query getLeaderboardTournament($tournamentId: ID!) {
  base: node(id: $tournamentId) {
    ... on Tournament {
      type
    }
  }
  node(id: $tournamentId) {
    ... on CourseTournament {
      participantGroups {
        id
        name
        criteria {
          ... on genderCriteria {
            gender
          }
          ... on handicapRangeCriteria {
            start
            end
          }
          ... on trackmanHandicapCriteria {
            requiredRounds
          }
        }
      }
      id
      rounds {
        id
        roundNumber
        roundState
        course {
          displayName
          courseLocation
        }
        embeddedGame {
          closestToPin {
            holes
          }
          longestDrive {
            holes
          }
        }
      }
    }
  }
}`;
