interface prizeCalculatorProps {
  roundParticipants: number;
  roundPrizeTotal: number;
  roundPrizeSidebets?: number;
}

export const prizeCalculatorLastSeason = ({
  roundParticipants,
  roundPrizeTotal,
  roundPrizeSidebets,
}: prizeCalculatorProps) => {
  const N_PLAYERS = roundParticipants;
  const TOTAL_PRIZE = roundPrizeTotal - (roundPrizeSidebets || 35000);
  const RATIO = 1.3;

  const prizeList = [];
  let prizeSum = 0;

  const remainder =
    TOTAL_PRIZE - (100 + 150 + 200 + 250 + 300 + 350) * N_PLAYERS * 0.05;

  for (let i = 0; i < N_PLAYERS; i++) {
    const RANK = i + 1;
    prizeList[i] = Math.round(
      ((RATIO - 1) * remainder) / Math.pow(RATIO, RANK)
    );
    const percentile = RANK / N_PLAYERS;
    if (percentile <= 0.05) prizeList[i] += 350;
    if (percentile > 0.05 && percentile <= 0.1) prizeList[i] += 300;
    if (percentile > 0.1 && percentile <= 0.15) prizeList[i] += 250;
    if (percentile > 0.15 && percentile <= 0.2) prizeList[i] += 200;
    if (percentile > 0.2 && percentile <= 0.25) prizeList[i] += 150;
    if (percentile > 0.25 && percentile <= 0.3) prizeList[i] += 100;
    if (percentile > 0.3) prizeList[i] = 0;
    if (i > 0 && prizeList[i] > 0) {
      prizeSum += prizeList[i];
    }
  }

  prizeList[0] = TOTAL_PRIZE - prizeSum;

  return prizeList;
};
