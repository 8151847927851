import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { sbBlok } from "../../types/sb-types";
import GridElement from "../GridElement";
import GridWrapper from "../GridWrapper";
import "swiper/css";
import styles from "./Testimonials.module.css";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

const Testimonials = ({ blok }: sbBlok) => {
  return (
    <GridWrapper className={styles.testimonialsGridWrapper}>
      <GridElement>
        <Swiper
          {...storyblokEditable(blok)}
          className={styles.swiperWrapper}
          slidesPerView={1}
          spaceBetween={30}
          speed={500}
          centeredSlides
          pagination={{ clickable: true }}
          modules={[Pagination, Navigation]}
          navigation={{
            nextEl: `.${styles.arrowRight}`,
            prevEl: `.${styles.arrowLeft}`,
          }}
          onClick={(swiper, e) => {
            e.stopPropagation();
            swiper.clickedIndex !== undefined &&
              swiper.slideTo(swiper.clickedIndex, 100);
          }}
        >
          {blok?.testimonial_items?.map((i: sbBlok) => {
            return (
              <SwiperSlide key={i._uid} className={styles.slide}>
                <div className={styles.slideContainer}>
                  {i?.image?.filename && (
                    <div className={styles.slideImage}>
                      <Image
                        src={i.image.filename}
                        alt={i.image.alt}
                        layout="fill"
                        objectFit="cover"
                      />
                    </div>
                  )}
                  <div className={styles.slideContent}>
                    <div className={styles.testimonialsContent}>
                      {i?.content}
                    </div>
                    <div className={styles.testimonialsName}>{i?.name}</div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
          <div className={`${styles.arrowLeft} ${styles.arrow}`}>
            <ArrowBackIosNewOutlinedIcon />
          </div>
          <div className={`${styles.arrowRight} ${styles.arrow}`}>
            <ArrowForwardIosOutlinedIcon />
          </div>
        </Swiper>
      </GridElement>
    </GridWrapper>
  );
};

export default Testimonials;
