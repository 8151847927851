export const groupInnerVariants = {
  open: {
    height: "auto",
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
  initial: {
    height: 0,
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
};
