import { AnimatePresence, motion } from "framer-motion";
import CountryFlag from "react-country-flag";
import { InfiniteData } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import ScoreCard from "../ScoreCard";
import LeaderboardLoading from "./LeaderboardLoading";
import { groupInnerVariants } from "./animations";
import styles from "./Leaderboard.module.css";
import { ReactNode, useEffect, useState } from "react";

interface TournamentPlayerData {
  id: string;
  playerId: string;
  playername: string;
  gender: string;
  nationality: string;
  category: string;
  picture: string;
  hcp: string;
  total: {
    pos: number;
    thru: number;
    state: string;
    toPar: number;
    score: number;
  };
  latestRound: { scorecardId: string };
  rounds: [{ scorecardId: string; state: string }];
}

interface LeaderboardData {
  data: {
    node: {
      leaderboard: {
        records: {
          items: [TournamentPlayerData];
        };
      };
    };
  };
}

interface StrokeplayLeaderboardProps {
  gender?: string;
  data?: InfiniteData<AxiosResponse<LeaderboardData, any>>;
  favoritePlayers?: [TournamentPlayerData];
  isLoading: boolean;
  ErrorMessage: () => JSX.Element | null;
  favouritesList: string[];
  setFavouritesList: (favourites: string[]) => void;
  refetchFavourites: () => void;
  tournamentName?: string;
  isAmateur?: boolean;
}

const PlayerDataTable = ({
  children,
  isFavoriteLeaderboard,
  ErrorMessage,
  isLoading,
}: {
  children: ReactNode;
  isFavoriteLeaderboard?: boolean;
  ErrorMessage: () => JSX.Element | null;
  isLoading: boolean;
}) => {
  return (
    <div
      role="table"
      className={`${styles.leaderboardTable} ${styles.leaderboardTableMain}`}
      style={{
        marginBottom: isFavoriteLeaderboard ? "6rem" : 0,
        minHeight: isFavoriteLeaderboard ? 0 : "800px",
      }}
    >
      <div role="thead" className={styles.tableHeader}>
        <div role="th" className={styles.tableHeaderCell}>
          Fav
        </div>
        <div role="th" className={styles.tableHeaderCell}>
          Pos
        </div>
        <div role="th" className={styles.tableHeaderCell}>
          <span className={styles.thCellLabel}>Country</span>
        </div>
        <div role="th" className={styles.tableHeaderCell}>
          Player
        </div>
        <div role="th" className={styles.tableHeaderCell}>
          Score
        </div>
        <div role="th" className={styles.tableHeaderCell}>
          Thru
        </div>
        <div role="th" className={styles.tableHeaderCell}>
          Total
        </div>
      </div>
      <div role="tbody" className={styles.tableBody}>
        <ErrorMessage />
        {children}
        {isLoading && (
          <LeaderboardLoading
            columns={7}
            rows={isFavoriteLeaderboard ? 5 : 25}
          />
        )}
      </div>
    </div>
  );
};

const PlayerDataRow = ({
  player,
  isPosFirstKind,
  pageIndex,
  playerIndex,
  gender,
  tournamentName,
  favouritesList,
  setFavouritesList,
  isAmateur,
}: {
  player: TournamentPlayerData;
  isPosFirstKind: boolean;
  pageIndex: number;
  playerIndex: number;
  gender?: string;
  tournamentName?: string;
  favouritesList: string[];
  setFavouritesList: (favourites: string[]) => void;
  isAmateur?: boolean;
}) => {
  const [isRowOpen, setIsRowOpen] = useState(false);

  return (
    <div
      role="tr"
      className={`${styles.tableRowGroup}${
        gender &&
        player.gender &&
        player.gender.toLowerCase() !== gender?.toLowerCase()
          ? " " + styles.tableRowGroupHidden
          : ""
      }${isPosFirstKind ? " " + styles.tableRowGroupPostFirstKind : ""}${
        isRowOpen ? " " + styles.tableRowGroupActive : ""
      }`}
      key={player.id}
    >
      <div
        role="tr"
        className={styles.tableRow}
        onClick={() => {
          window.dataLayer = window.dataLayer || [];
          if (!isRowOpen && player.rounds[0]?.scorecardId) {
            window.dataLayer.push({
              event: "openScorecard",
              scorecardId: player.rounds[0].scorecardId,
              playerId: player.playerId,
              tournamentName: tournamentName || "",
              playerState: player.rounds[0].state,
            });
            setIsRowOpen(true);
          }
          if (isRowOpen) {
            setIsRowOpen(false);
          }
        }}
      >
        <div role="td" className={styles.tableRowCell}>
          <span
            onClick={(e) => {
              e.stopPropagation();
              const findFavourite = favouritesList.find(
                (i: string) => i === player.playerId
              );
              if (findFavourite) {
                const removeFavourite = favouritesList.filter(
                  (i: string) => i !== player.playerId
                );
                setFavouritesList(removeFavourite);
                localStorage.setItem(
                  "leaderboard_favourites",
                  JSON.stringify(removeFavourite)
                );
              } else {
                const addFavourite = [...favouritesList, player.playerId];
                setFavouritesList(addFavourite);
                localStorage.setItem(
                  "leaderboard_favourites",
                  JSON.stringify(addFavourite)
                );
              }
            }}
          >
            {favouritesList.find((i: string) => i === player.playerId) ? (
              <StarOutlinedIcon style={{ fontSize: "2.4rem" }} />
            ) : (
              <StarBorderOutlinedIcon style={{ fontSize: "2.4rem" }} />
            )}
          </span>
        </div>
        <div role="td" className={styles.tableRowCell}>
          {player.total.state.toLowerCase() === "did_not_finish" ||
          player.total.state.toLowerCase() === "no_show"
            ? "DNF"
            : isPosFirstKind || (pageIndex === 0 && playerIndex === 0)
            ? player.total.pos
            : ""}
        </div>
        <div role="td" className={styles.tableRowCell}>
          {player.nationality && (
            <CountryFlag
              countryCode={player.nationality}
              className={styles.playerNationality}
              svg
            />
          )}
        </div>
        <div role="td" className={styles.tableRowCell}>
          {player.playername}
          {player.category === "AMATEUR" ? " (A)" : null}
          <span className={styles.tableRowCellOverflow} />
        </div>
        <div role="td" className={styles.tableRowCell}>
          {player.total.toPar < 0 && (
            <span className={styles.highlight}>{player.total.toPar}</span>
          )}
          {player.total.toPar == 0 && (
            <span className={`${styles.highlight} ${styles.highlightPar}`}>
              E
            </span>
          )}
          {player.total.toPar > 0 && (
            <span className={`${styles.highlight} ${styles.highlightOverPar}`}>
              +{player.total.toPar}
            </span>
          )}
        </div>
        <div role="td" className={styles.tableRowCell}>
          {player.total.thru === 18 ? "F" : player.total.thru}
        </div>
        <div role="td" className={styles.tableRowCell}>
          {player.total.score || "-"}
        </div>
      </div>
      <motion.div
        role="tr"
        className={styles.tableRowGroupInner}
        variants={groupInnerVariants}
        initial="initial"
        animate={isRowOpen ? "open" : "initial"}
        exit="initial"
      >
        <AnimatePresence>
          {isRowOpen && (
            <ScoreCard
              isNineHoles={
                player.total.state.toLowerCase() === "played" &&
                player.total.thru === 9
              }
              isAmateur={isAmateur}
              fetchData={!!player.latestRound?.scorecardId && isRowOpen}
              shouldIntervalRefetch={player.total.thru < 18}
              playerId={player.playerId}
              playerScoreCardId={player.latestRound?.scorecardId}
              profilePicture={player.picture}
              playerName={player.playername}
              playerHcp={player.hcp}
              playerGender={player.gender}
              isOpen={isRowOpen}
            />
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

const StrokeplayLeaderboard = ({
  data,
  gender,
  isLoading,
  ErrorMessage,
  favoritePlayers,
  favouritesList,
  setFavouritesList,
  refetchFavourites,
  tournamentName,
  isAmateur,
}: StrokeplayLeaderboardProps) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    if (!!favouritesList.length) {
      refetchFavourites();
    }
  }, [favoritePlayers]);

  useEffect(() => {
    const getFavourites = localStorage.getItem("leaderboard_favourites");
    if (getFavourites) {
      setFavouritesList(JSON.parse(getFavourites));
    } else {
      localStorage.setItem("leaderboard_favourites", JSON.stringify([]));
    }
  }, []);

  return (
    <>
      {!isFirstRender && !!favoritePlayers?.length && (
        <PlayerDataTable
          isFavoriteLeaderboard
          ErrorMessage={ErrorMessage}
          isLoading={isLoading}
        >
          {favoritePlayers?.map(
            (player: TournamentPlayerData, playerIndex: number) => {
              return (
                <PlayerDataRow
                  key={player.playerId}
                  player={player}
                  pageIndex={0}
                  playerIndex={playerIndex}
                  isPosFirstKind
                  gender={gender}
                  tournamentName={tournamentName}
                  favouritesList={favouritesList}
                  setFavouritesList={setFavouritesList}
                  isAmateur={isAmateur}
                />
              );
            }
          )}
        </PlayerDataTable>
      )}
      <PlayerDataTable ErrorMessage={ErrorMessage} isLoading={isLoading}>
        {data?.pages?.map(
          (page: { data: LeaderboardData }, pageIndex: number) => {
            if (
              pageIndex === 0 &&
              !page?.data?.data?.node?.leaderboard?.records?.items.length
            ) {
              return (
                <h2 key={pageIndex} className={styles.infoHeading}>
                  No results found
                </h2>
              );
            }

            return page?.data?.data?.node?.leaderboard?.records?.items?.map(
              (player: TournamentPlayerData, playerIndex: number) => {
                const isPosFirstKind =
                  (pageIndex === 0 &&
                    playerIndex > 0 &&
                    player.total.pos !==
                      page.data.data.node.leaderboard?.records.items[
                        playerIndex - 1
                      ].total.pos) ||
                  (pageIndex > 0 &&
                    playerIndex > 0 &&
                    player.total.pos !==
                      page.data.data.node.leaderboard?.records.items[
                        playerIndex - 1
                      ].total.pos &&
                    player.total.pos !==
                      data.pages[pageIndex - 1].data.data.node.leaderboard
                        ?.records.items[
                        data.pages[pageIndex - 1].data.data.node.leaderboard
                          ?.records.items.length - 1
                      ].total.pos);
                return (
                  <PlayerDataRow
                    key={player.playerId}
                    player={player}
                    pageIndex={pageIndex}
                    playerIndex={playerIndex}
                    isPosFirstKind={isPosFirstKind}
                    gender={gender}
                    tournamentName={tournamentName}
                    favouritesList={favouritesList}
                    setFavouritesList={setFavouritesList}
                    isAmateur={isAmateur}
                  />
                );
              }
            );
          }
        )}
      </PlayerDataTable>
    </>
  );
};

export default StrokeplayLeaderboard;
