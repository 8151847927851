import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import { sbBlok } from "../../types/sb-types";
import GridElement from "../GridElement";
import GridWrapper from "../GridWrapper";
import styles from "./ImageComponent.module.css";

interface ImageComponentProps {
  blok: sbBlok;
}

const ImageComponent = ({ blok }: ImageComponentProps) => {
  const { image } = blok;

  return (
    <GridWrapper
      padding_top={blok.spacing_top?.length > 0 && blok.spacing_top[0].option}
      padding_bottom={
        blok.spacing_bottom?.length > 0 && blok.spacing_bottom[0].option
      }
    >
      <GridElement>
        <div {...storyblokEditable(blok)} className={styles.imageComponent}>
          <Image
            src={image.filename}
            alt={image.alt}
            layout="fill"
            objectFit="contain"
          />
        </div>
      </GridElement>
    </GridWrapper>
  );
};

export default ImageComponent;
