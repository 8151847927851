import { useState } from "react";
import { sbBlok } from "../../types/sb-types";
import GridElement from "../GridElement";
import GridWrapper from "../GridWrapper";
import { prizeCalculatorLastSeason } from "../../utils/prize-calculator-last-season";
import styles from "./PrizeList.module.css";
import Dropdown from "../Dropdown/Dropdown";
import { FIRST_SEASON_TOURNAMENT_IDS } from "../../utils/constants";

interface prizeType {
  round_1: number;
  round_2: number;
  round_3: number;
  round_4: number;
  round_5: number;
}

interface prizeTableType {
  round_1: number[];
  round_2: number[];
  round_3: number[];
  round_4: number[];
  round_5: number[];
}

interface PrizeListOldProps {
  blok?: sbBlok;
  defaultSpacing?: string;
  tournamentId?: string;
}

const PRIZE_DISTRIBUTION = {
  round_1: 126600,
  round_2: 150600,
  round_3: 150500,
  round_4: 148200,
  round_5: 150600,
};

const TOTAL_PLAYERS = {
  round_1: 267,
  round_2: 506,
  round_3: 505,
  round_4: 482,
  round_5: 506,
};

export const PRIZE_TABLE: { [key: string]: any } = {
  round_1: prizeCalculatorLastSeason({
    roundParticipants: 267,
    roundPrizeTotal: 126600,
  }),
  round_2: prizeCalculatorLastSeason({
    roundParticipants: 506,
    roundPrizeTotal: 150600,
  }),
  round_3: prizeCalculatorLastSeason({
    roundParticipants: 505,
    roundPrizeTotal: 150500,
  }),
  round_4: prizeCalculatorLastSeason({
    roundParticipants: 482,
    roundPrizeTotal: 148200,
  }),
  round_5: prizeCalculatorLastSeason({
    roundParticipants: 506,
    roundPrizeTotal: 150600,
  }),
  round_6: [
    20000,
    12600,
    8100,
    6300,
    4725,
    3511,
    3009,
    2508,
    2247,
    2006,
    1846,
    1725,
    1615,
    1540,
    1475,
    1414,
    1354,
    1294,
    1244,
    1204,
    1164,
    1134,
    1103,
    1073,
    1043,
    1013,
    983,
    953,
    923,
    893,
    ...Array.from(Array(70)).map(() => 0),
  ],
};

const dummyPrizeListSelectOptions = [...new Array(6)].map((_, index) => {
  return {
    _uid: index + 1,
    id: `round_${index + 1}`,
    name: index === 5 ? "Finale" : `Round ${index + 1}`,
  };
});

const PrizeListOld = ({
  blok,
  defaultSpacing,
  tournamentId,
}: PrizeListOldProps) => {
  const blokData = blok
    ? blok
    : {
        _uid: "",
        component: "",
        select_options: dummyPrizeListSelectOptions,
      };

  const selectOptions = blokData?.select_options?.map((option: sbBlok) => ({
    label: option?.name,
    id: option?._uid,
  }));

  const tournamentIdFirstSeasonIndex = FIRST_SEASON_TOURNAMENT_IDS.indexOf(
    tournamentId as string
  );
  const firstSeasonTournamentRound = `round_${
    tournamentIdFirstSeasonIndex + 1
  }`;

  const [selectedOption, setSelectedOption] = useState(
    tournamentIdFirstSeasonIndex !== -1
      ? tournamentIdFirstSeasonIndex + 1
      : blokData?.select_options[0]._uid
  );

  const currentRound = blokData?.select_options?.find(
    (r: sbBlok) => r._uid === selectedOption
  );

  const [selectedView, setSelectedView] = useState(
    tournamentIdFirstSeasonIndex !== -1
      ? firstSeasonTournamentRound
      : currentRound?.id
  );

  const handleSelectChange = (option: string) => {
    const getRound = blokData?.select_options?.find(
      (r: sbBlok) => r._uid === option
    );
    setSelectedView(getRound.id);
    setSelectedOption(option);
  };

  return (
    <GridWrapper
      padding_top={
        (blokData?.spacing_top?.length > 0 &&
          blokData?.spacing_top[0].option) ||
        defaultSpacing
      }
      padding_bottom={
        (blokData?.spacing_bottom?.length > 0 &&
          blokData?.spacing_bottom[0].option) ||
        defaultSpacing
      }
    >
      <GridElement
        gridLayout={[
          [0, 1, 4, 1],
          [961, 6, 6, 1],
          [1401, 12, 10, 1],
        ]}
      >
        {blokData?.title && (
          <h1 className={styles.prizeListTitle}>{blokData.title}</h1>
        )}
        <div className={styles.selectWrapper}>
          <Dropdown
            defaultValue={{
              label: currentRound?.name,
              id: currentRound?._uid,
            }}
            options={selectOptions}
            onChange={(e: any) => {
              handleSelectChange(e.id);
            }}
          />
        </div>
      </GridElement>
      <GridElement
        gridLayout={[
          [0, 1, 4, 2],
          [961, 7, 4, 2],
          [1401, 14, 6, 2],
        ]}
      >
        <div className={styles.prizeListSubTitleWrapper}>
          <h3 className={styles.prizeListSubTitle}>
            <span className={styles.prizeListSubTitleLabel}>Purse</span>
            <span className={styles.prizeListSubTitleValue}>
              $
              {selectedView === "round_6"
                ? (100000).toLocaleString("en-US")
                : PRIZE_DISTRIBUTION?.[
                    selectedView as keyof prizeType
                  ]?.toLocaleString("en-US")}
            </span>
          </h3>
          <h3 className={styles.prizeListSubTitle}>
            <span className={styles.prizeListSubTitleLabel}>Players</span>
            <span className={styles.prizeListSubTitleValue}>
              {selectedView === "round_6"
                ? 100
                : TOTAL_PLAYERS?.[selectedView as keyof {}]}
            </span>
          </h3>
        </div>
      </GridElement>
      <GridElement
        gridLayout={[
          [0, 1, 4, 3],
          [961, 5, 8, 3],
          [1401, 9, 16, 3],
        ]}
      >
        <ol className={styles.prizeList}>
          <li className={styles.prizeListItem}>
            <span>Position</span>
            <span>Prize money</span>
          </li>
          {PRIZE_TABLE[selectedView as keyof prizeTableType]?.map(
            (p: number, i: number) =>
              p > 0 && (
                <li key={i} className={styles.prizeListItem}>
                  <span>{i + 1}</span>
                  <span>${p.toLocaleString("en-US")}</span>
                </li>
              )
          )}
          {PRIZE_TABLE[selectedView as keyof prizeTableType]?.length > 1 && (
            <li className={styles.prizeListItem}>
              <span>
                {PRIZE_TABLE[selectedView as keyof prizeTableType]?.indexOf(0) +
                  1}
                ...
                {PRIZE_TABLE[selectedView as keyof prizeTableType]?.length}
              </span>
              <span>$0</span>
            </li>
          )}
        </ol>
      </GridElement>
    </GridWrapper>
  );
};

export default PrizeListOld;
