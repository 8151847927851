export const TournamentClosestToPin = `query getClosetToPinAggregatedLeaderboard(
  $publishedTournamentId: ID!,
  $roundId: ID!,
  $skip: Int,
  $take: Int,
  $participantGroupId: String,
  $gender: Gender,
  $nationality: [String!],
  $playNow: Boolean,
  $searchText: String
) {
  node(id: $publishedTournamentId) {
    ... on CourseTournament {
      unit
      aggregatedClosestToPinLeaderboard(
        roundId: $roundId
        gender: $gender
        participantGroupId: $participantGroupId
      ) {
        records(skip: $skip, take: $take, nationality: $nationality, playNow: $playNow, gender: $gender, searchText: $searchText) {
          items {
            status
            playername
            playerId
            nationality
            gender
            total {
              distanceToPin
              pos
              state
            }
            holes {
              holeNumber
              distanceToPin
              distanceToPinWithPenalty
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
      }
    }
  }
}`;
