import { useEffect, useRef, useState } from "react";
import { storyblokEditable } from "@storyblok/react";
import { useInView } from "framer-motion";
import dayjs from "dayjs";
import { sbBlok } from "../../types/sb-types";
import GridElement from "../GridElement";
import GridWrapper from "../GridWrapper";
import styles from "./TeaserRow.module.css";
import { useWindowSize } from "../../hooks/useWindowSize";
import {
  ENTRY_FEE,
  INITIAL_PURSE,
  INITIAL_PURSE_ELEVATED,
} from "../../utils/constants";
import { Tournament } from "../../types/tournament";
import { isElevatedRound, isFinalRound } from "../../utils/tournaments";

interface TeaserRowProps {
  blok: sbBlok;
  tournament: Tournament;
}

const TeaserRow = ({ blok, tournament }: TeaserRowProps) => {
  const [isHovered, setHovered] = useState(false);
  const teaserRowRef = useRef(null);
  const isInView = useInView(teaserRowRef);
  const { isDesktop, isTablet, isMobile } = useWindowSize();

  const playersCount =
    tournament?.participants?.allInvitationsCount -
    tournament?.participants?.declinedCount -
    tournament?.participants?.withdrawnCount;

  const isElevated = tournament && isElevatedRound(false, tournament.name);
  const isFinal = tournament && isFinalRound(false, tournament.name);

  const TOTAL_PRIZE = isFinal
    ? INITIAL_PURSE
    : (isElevated ? INITIAL_PURSE_ELEVATED : INITIAL_PURSE) +
      ENTRY_FEE * playersCount;

  const teaserRowProps = {
    tournament: {
      label_col_1: tournament?.name,
      label_col_2: "Purse",
      label_col_3: "Players",
      info_col_1: `${dayjs(tournament?.startDate).format("D MMM")} - ${dayjs(
        tournament?.endDate
      ).format("D MMM")}`,
      info_col_2: TOTAL_PRIZE.toLocaleString("en-US"),
      info_col_3: playersCount,
    },
    storyblok: { ...blok },
  };
  const teaserRowData =
    tournament &&
    Object.keys(tournament).length !== 0 &&
    blok?.is_auto_generated_content
      ? teaserRowProps.tournament
      : teaserRowProps.storyblok;

  const teaserElementTransition = {
    transition: "all 0.8s",
  };

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, []);

  const animateTeaserElement =
    (isDesktop && isHovered) ||
    (isTablet && isInView) ||
    (isMobile && isInView);

  if (isFirstRender) return <div ref={teaserRowRef} />;

  return (
    <div
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      <GridWrapper
        className={styles.teaserRowGridWrapper}
        margin_top={blok?.margin_top?.length > 0 && blok.margin_top[0].option}
        margin_bottom={
          blok?.margin_bottom?.length > 0 && blok.margin_bottom[0].option
        }
      >
        <GridElement gridLayout={[[1401, 5, 24]]}>
          <div {...storyblokEditable(blok)}>
            <h2 className={styles.teaserHeadline}>{blok?.teaser_headline}</h2>
            <div className={styles.teaserRow} ref={teaserRowRef}>
              <div
                className={styles.teaserRowItem}
                style={{
                  transform: animateTeaserElement
                    ? "translate(0,0)"
                    : `translate(${
                        isDesktop ? "-4rem" : isTablet ? "-2rem" : 0
                      }, ${isMobile ? "2rem" : 0})`,
                  opacity: animateTeaserElement ? 1 : 0.5,
                  ...teaserElementTransition,
                }}
              >
                {teaserRowData?.info_col_1 && (
                  <p className={styles.teaserRowInfo}>
                    {teaserRowData.info_col_1}
                  </p>
                )}
                {teaserRowData?.label_col_1 && (
                  <p className={styles.teaserRowLabel}>
                    {teaserRowData.label_col_1}
                  </p>
                )}
              </div>
              <div
                className={styles.teaserRowItem}
                style={{
                  transform: animateTeaserElement
                    ? "translate(0,0)"
                    : `translate(0, ${isMobile ? "4rem" : 0})`,
                  opacity: animateTeaserElement ? 1 : 0.5,
                  ...teaserElementTransition,
                }}
              >
                {teaserRowData?.info_col_2 && (
                  <p className={styles.teaserRowInfo}>
                    {teaserRowData.info_col_2}
                  </p>
                )}
                {teaserRowData?.label_col_2 && (
                  <p className={styles.teaserRowLabel}>
                    {teaserRowData.label_col_2}
                  </p>
                )}
              </div>
              <div
                className={styles.teaserRowItem}
                style={{
                  transform: animateTeaserElement
                    ? "translate(0,0)"
                    : `translate(${
                        isDesktop ? "4rem" : isTablet ? "2rem" : 0
                      }, ${isMobile ? "6rem" : 0})`,
                  opacity: animateTeaserElement ? 1 : 0.5,
                  ...teaserElementTransition,
                }}
              >
                {teaserRowData?.info_col_3 && (
                  <p className={styles.teaserRowInfo}>
                    {teaserRowData.info_col_3}
                  </p>
                )}
                {teaserRowData?.label_col_3 && (
                  <p className={styles.teaserRowLabel}>
                    {teaserRowData.label_col_3}
                  </p>
                )}
              </div>
            </div>
          </div>
        </GridElement>
      </GridWrapper>
    </div>
  );
};

export default TeaserRow;
