import dayjs from "dayjs";
import {
  ENTRY_FEE,
  FIRST_PLACE_PRICE,
  FIRST_PLACE_PRICE_ELEVATED,
  INITIAL_PURSE,
  INITIAL_PURSE_ELEVATED,
  PAST_FINALE_TOURNAMENT_ID,
} from "./constants";
import { Tournament } from "../types/tournament";

export const isPossibleToJoin = (participants: number) => {
  return participants <= 1000;
};

export const isPossibleToJoinAM = (
  endDate: string | undefined,
  isPublished: boolean | undefined
) => {
  if (!endDate || !isPublished) return null;
  return dayjs(endDate).isAfter(new Date());
};

export const isPastTournament = (tournament: Tournament) => {
  return dayjs(tournament?.startDate).isBefore(new Date());
};

export const sortByStartDate = (tournaments: Array<Tournament>) => {
  return tournaments?.sort((a: Tournament, b: Tournament) =>
    dayjs(a?.startDate) >= dayjs(b?.startDate) ? 1 : -1
  );
};

export const sortTournamentsActualFirst = (tournaments: Array<Tournament>) => {
  const futureTournaments = sortByStartDate(
    tournaments?.filter((t: Tournament) => dayjs(t?.startDate).isAfter(dayjs()))
  );

  const restOfTournaments = sortByStartDate(
    tournaments?.filter(
      (t: Tournament) =>
        dayjs(t?.startDate).isBefore(dayjs()) ||
        dayjs(t?.startDate).isSame(dayjs())
    )
  );

  return futureTournaments?.concat(restOfTournaments);
};

export const getActualTournament = (tournaments: Array<Tournament>) => {
  return sortByStartDate(
    tournaments?.filter((t: Tournament) => dayjs(t?.endDate).isAfter(dayjs()))
  );
};

export const isLastSeasonTournament = (tournament: Tournament | undefined) => {
  const lastSeasonEndDate = "2023-04-3";
  const tournamentEndDate = dayjs(tournament?.endDate).format("YYYY-MM-DD");
  return dayjs(tournamentEndDate).isBefore(dayjs(lastSeasonEndDate));
};

export const getTotalPrize = (
  playersCount: number,
  elevatedRound?: boolean,
  finalRound?: boolean
) => {
  if (finalRound) return INITIAL_PURSE;
  return (
    (elevatedRound ? INITIAL_PURSE_ELEVATED : INITIAL_PURSE) +
    ENTRY_FEE * playersCount
  );
};

const initialPrizeForStrokeAlgorithm = (
  elevatedRound: boolean,
  isFinalRound: boolean,
  isRound456: boolean
) => {
  if (elevatedRound && !isRound456) return 70000;
  if (elevatedRound && isRound456) return 80000;
  if (isFinalRound) return 63000;
  if (isRound456) return 50000;

  return 40000;
};

export const prizeCalculator = (
  playersCount: number,
  isLastSeasonTournament: boolean,
  elevatedRound: boolean,
  isFinalRound: boolean,
  isRound456: boolean
) => {
  const prizeList = [];

  if (playersCount < 10 && !isLastSeasonTournament) {
    prizeList[0] = elevatedRound
      ? FIRST_PLACE_PRICE_ELEVATED
      : FIRST_PLACE_PRICE;
    return prizeList;
  }

  let prizeSum = 0;
  const RATIO = 1.3;
  const initialPrize = initialPrizeForStrokeAlgorithm(
    elevatedRound,
    isFinalRound,
    isRound456
  );

  const totalPrize = isFinalRound
    ? initialPrize
    : initialPrize + ENTRY_FEE * playersCount;
  const remainder =
    totalPrize - (100 + 150 + 200 + 250 + 300 + 350) * playersCount * 0.05;

  for (let i = 0; i < playersCount; i++) {
    const RANK = i + 1;
    prizeList[i] = Math.round(
      ((RATIO - 1) * remainder) / Math.pow(RATIO, RANK)
    );
    const percentile = RANK / playersCount;
    if (percentile <= 0.05) prizeList[i] += 350;
    if (percentile > 0.05 && percentile <= 0.1) prizeList[i] += 300;
    if (percentile > 0.1 && percentile <= 0.15) prizeList[i] += 250;
    if (percentile > 0.15 && percentile <= 0.2) prizeList[i] += 200;
    if (percentile > 0.2 && percentile <= 0.25) prizeList[i] += 150;
    if (percentile > 0.25 && percentile <= 0.3) prizeList[i] += 100;
    if (percentile > 0.3) prizeList[i] = 0;
    if (i > 0 && prizeList[i] > 0) {
      prizeSum += prizeList[i];
    }
  }

  prizeList[0] = totalPrize - prizeSum;
  !isLastSeasonTournament &&
    prizeList?.unshift(
      elevatedRound ? FIRST_PLACE_PRICE_ELEVATED : FIRST_PLACE_PRICE
    );
  return prizeList;
};

export const isPastFinaleTournament = (tournamentId: string) => {
  return tournamentId === PAST_FINALE_TOURNAMENT_ID;
};

export const isElevatedRound = (
  isLastSeasonTournament: boolean,
  tournamentName: string
) =>
  !isLastSeasonTournament &&
  (tournamentName?.toLowerCase().includes("round 3") ||
    tournamentName?.toLowerCase().includes("round 6"));

export const isFinalRound = (
  isLastSeasonTournament: boolean,
  tournamentName: string
) =>
  !isLastSeasonTournament && tournamentName?.toLowerCase()?.includes("final");

export const isRoundNumber456 = (tournamentName: string) =>
  tournamentName?.toLowerCase().includes("round 4") ||
  tournamentName?.toLowerCase().includes("round 5") ||
  tournamentName?.toLowerCase().includes("round 6");

export const isFreeAmateur = (tournament: Tournament) =>
  tournament?.keyValues?.find((keyValue) => {
    return (
      keyValue.key === "NextTour:TournamentKind" &&
      keyValue.value.toLowerCase() === "free"
    );
  });

export const isPremiumAmateur = (tournament: Tournament) =>
  tournament?.keyValues?.find((keyValue) => {
    return (
      keyValue.key === "NextTour:TournamentKind" &&
      keyValue.value.toLowerCase() === "amateur"
    );
  });

export const isNowPlayingTournament = (
  startDate: string | undefined,
  endDate: string | undefined
) => {
  return (
    dayjs(endDate).isAfter(new Date()) &&
    (dayjs(startDate).isSame(new Date()) ||
      dayjs(startDate).isBefore(new Date()))
  );
};
