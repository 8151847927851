import { storyblokEditable } from "@storyblok/react";
import { sbBlok } from "../../types/sb-types";
import GridWrapper from "../GridWrapper";
import styles from "./Faq.module.css";
import FaqItem from "./FaqItem";
import GridElement from "../GridElement/GridElement";

const Faq = ({ blok }: sbBlok) => {
  return (
    <section {...storyblokEditable(blok)}>
      <GridWrapper
        padding_top={blok.spacing_top?.length > 0 && blok.spacing_top[0].option}
        padding_bottom={
          blok.spacing_bottom?.length > 0 && blok.spacing_bottom[0].option
        }
        gridClassName={styles.faqGrid}
      >
        <GridElement
          gridLayout={[
            [961, 3, 12],
            [1401, 7, 20],
          ]}
        >
          {blok.title && <h1 className={styles.faqTitle}>{blok.title}</h1>}
          {blok.content.length > 0 && (
            <div className={styles.faqCollapsibleItem}>
              {blok.content.map((blok: sbBlok) => {
                return (
                  <FaqItem
                    blok={blok}
                    key={blok._uid}
                    _uid={blok._uid}
                    component={blok.component}
                  />
                );
              })}
            </div>
          )}
        </GridElement>
      </GridWrapper>
    </section>
  );
};

export default Faq;
