import styles from "./Leaderboard.module.css";

const LeaderboardLoading = ({
  rows = 25,
  columns = 4,
}: {
  rows?: number;
  columns?: number;
}) => {
  return (
    <>
      {Array.from(Array(rows)).map((_item: any, i: number) => (
        <div role="tr" className={styles.tableRowGroup} key={i}>
          <div role="tr" className={styles.tableRow}>
            {Array.from(Array(columns)).map((_item: any, n: number) => (
              <div role="td" className={styles.tableRowCell} key={n}>
                <span className={styles.loading} />
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default LeaderboardLoading;
