import Image from "next/image";
import { sbBlok } from "../../types/sb-types";
import Cta from "../Cta";
import styles from "./BlogPost.module.css";
import { useWindowSize } from "../../hooks/useWindowSize";
import { motion } from "framer-motion";

interface BlogPostProps {
  isLarge?: boolean;
  ctaType?: string;
  blok?: sbBlok;
  isLoading?: boolean;
}

const BlogPost = ({ isLarge, ctaType, blok, isLoading }: BlogPostProps) => {
  const rootClasses = [styles.blogPostCard];
  if (isLarge) {
    rootClasses.push(styles.blogPostCardLarge);
  }
  const renderRootClasses = rootClasses.join(" ");

  const cta = blok?.cta_link && blok?.cta_link[0];

  const { isMobile, isDesktop, isTablet } = useWindowSize();

  const animationVariants = {
    initial: { scale: 1 },
    hover: { scale: 1.05 },
  };

  if (isLoading) {
    return (
      <div className={`${renderRootClasses} ${styles.blogPostCardLoading}`}>
        <div className={styles.cardImage} />
        <div className={styles.cardContent}>
          <div className={styles.cardTextContent}>
            <div className={styles.loadingMetaTitle} />
            <div className={styles.loadingTitle} />
            <div className={styles.loadingTitle} />
            <div className={styles.loadingCta} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <motion.div
        className={renderRootClasses}
        initial="initial"
        whileHover={isDesktop && !isLarge ? "hover" : "initial"}
        variants={animationVariants}
        transition={{
          ease: "linear",
        }}
        style={{ cursor: isLarge ? "default" : "pointer" }}
      >
        <div className={styles.cardImage}>
          {blok?.image && blok?.image?.filename && (
            <Image
              src={blok.image.filename}
              alt={blok.image.alt}
              layout="fill"
              objectFit="cover"
            />
          )}
        </div>
        <div className={styles.cardContent}>
          <div className={styles.cardTextContent}>
            <h2>{blok?.meta_title}</h2>
            <h1>{blok?.title}</h1>
            {isLarge && !isMobile && blok?.intro && (
              <p className={styles.blogPostCardParagraph}>{blok.intro}</p>
            )}
            {!isMobile && cta && ctaType === "button" && (
              <Cta
                className={styles.cardCta}
                href={cta.link.url}
                fullSlug={cta.link?.story?.full_slug}
                name={cta.name}
              />
            )}
          </div>
        </div>
      </motion.div>
      {isMobile && isLarge && (
        <motion.div
          className={styles.blogPostCardParagraphBtn}
          viewport={{ once: false }}
          initial={{
            opacity: isTablet ? 1 : 0.3,
            transform: isTablet ? "translate(0, 0)" : "translate(0, 6rem)",
          }}
          whileInView={{
            opacity: 1,
            transform: "translate(0, 0)",
          }}
          transition={{
            ease: "linear",
            duration: 0.8,
          }}
        >
          {blok?.intro && (
            <p className={styles.blogPostCardParagraph}>{blok.intro}</p>
          )}
          {cta && ctaType === "button" && (
            <Cta
              className={styles.cardCta}
              href={cta.link.url}
              fullSlug={cta.link?.story?.full_slug}
              name={cta.name}
            />
          )}
        </motion.div>
      )}
    </>
  );
};

export default BlogPost;
