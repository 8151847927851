import Papa from "papaparse";
import { useEffect, useState } from "react";
import CountryFlag from "react-country-flag";
import { motion } from "framer-motion";
import { useWindowSize } from "../../hooks/useWindowSize";
import styles from "./OOMLeaderboard.module.css";
import { sbBlok } from "../../types/sb-types";
import GridWrapper from "../GridWrapper/GridWrapper";
import GridElement from "../GridElement/GridElement";
import Dropdown, { Option } from "../Dropdown/Dropdown";

interface OOMLeaderboardProps {
  blok: sbBlok;
}

const OOMLeaderboard = ({ blok }: OOMLeaderboardProps) => {
  const [dataFromCSV, setDataFromCSV] = useState<any>();
  const [activeRows, setActiveRows] = useState<string[]>([]);
  const [selectedBoardId, setSelectedBoardId] = useState(
    Number(blok.default_data_id)
  );
  const { windowSize } = useWindowSize();

  const selectOptions = [
    {
      id: 1,
      label: "Season 1",
      data: blok?.data_1,
    },
    {
      id: 2,
      label: "Season 2",
      data: blok?.data_2,
    },
  ];

  const selectedLeaderboardData = selectOptions.find(
    (option) => option.id === selectedBoardId
  )?.data;

  useEffect(() => {
    if (selectedLeaderboardData?.filename) {
      Papa.parse(selectedLeaderboardData.filename, {
        download: true,
        complete: (res) => {
          setDataFromCSV(
            res.data.filter((i: any) => i.length > 1 && i[0].length)
          );
        },
      });
    }

    if (!selectedLeaderboardData?.filename) {
      setDataFromCSV([[]]);
    }
  }, [selectedLeaderboardData, selectedBoardId]);

  const handleRowClick = (id: string) => {
    if (activeRows.includes(id)) {
      setActiveRows(activeRows.filter((i) => i !== id));
    } else {
      setActiveRows([...activeRows, id]);
    }
  };

  const heightVariants = {
    initial: {
      height: 0,
      opacity: 0,
    },
    open: {
      height: "auto",
      opacity: 1,
    },
  };

  const handleSelectChange = (board: any) => {
    if (board.id !== selectedBoardId) {
      setSelectedBoardId(board.id);
    }
  };

  return (
    <GridWrapper className={styles.root}>
      <GridElement
        gridLayout={[
          [961, 2, 14],
          [1401, 4, 26],
        ]}
      >
        <GridWrapper>
          <GridElement gridLayout={[[1401, 4, 26]]}>
            <div className={styles.selectWrapper}>
              <Dropdown
                defaultValue={selectOptions[selectedBoardId - 1] as Option}
                options={selectOptions}
                onChange={(e: any) => {
                  handleSelectChange(e);
                }}
              />
            </div>
          </GridElement>
        </GridWrapper>
      </GridElement>
      <GridElement
        gridLayout={[
          [961, 2, 14, 2],
          [1401, 4, 26, 2],
        ]}
      >
        <div className={styles.leaderboardTableWrapper}>
          {dataFromCSV?.length > 0 && (
            <div role="table" className={styles.leaderboardTable}>
              <div role="thead" className={styles.tableHeader}>
                {dataFromCSV[0].slice(0, 4).map((i: string, n: number) => (
                  <div role="th" className={styles.tableHeaderCell} key={i}>
                    {n === 1 ? (
                      <span className={styles.thCellLabel}>{i}</span>
                    ) : (
                      i
                    )}
                  </div>
                ))}
              </div>
              <div role="tbody" className={styles.tableBody}>
                {dataFromCSV.slice(1).map((i: any) => {
                  const rowID: string = i[0] + i[2].replace(/\s/g, "") + i[3];
                  return (
                    <div
                      role="tr"
                      className={`${styles.tableRowGroup}${
                        activeRows.includes(rowID)
                          ? " " + styles.tableRowGroupActive
                          : ""
                      }`}
                      key={rowID}
                    >
                      <div
                        role="tr"
                        className={styles.tableRow}
                        onClick={() => handleRowClick(rowID)}
                      >
                        {i.slice(0, 4).map((d: any, n: number) => (
                          <div
                            role="td"
                            className={styles.tableRowCell}
                            key={n}
                          >
                            {n === 1 ? (
                              d ? (
                                <CountryFlag
                                  countryCode={d}
                                  style={{ fontSize: "1.5em" }}
                                  svg
                                />
                              ) : (
                                ""
                              )
                            ) : (
                              <span className={styles.data}>{d}</span>
                            )}
                          </div>
                        ))}
                      </div>
                      <motion.div
                        role="tr"
                        className={`${styles.tableRowGroupInner}${
                          dataFromCSV[0].length > 10 && windowSize.width < 961
                            ? " " + styles.tableRowGroupInnerLarge
                            : ""
                        }`}
                        variants={heightVariants}
                        initial="initial"
                        animate={
                          activeRows.includes(rowID) ? "open" : "initial"
                        }
                      >
                        <div className={styles.tableRowGroupInnerContent}>
                          {dataFromCSV[0].length <= 10 ? (
                            <>
                              <div role="thead" className={styles.tableHeader}>
                                {dataFromCSV[0]
                                  .slice(4, dataFromCSV[0].length)
                                  .map((d: any, n: number) => (
                                    <div
                                      role="td"
                                      className={styles.tableRowCell}
                                      key={n}
                                    >
                                      <span className={styles.data}>{d}</span>
                                    </div>
                                  ))}
                              </div>
                              <div role="tr" className={styles.tableRow}>
                                {i
                                  .slice(4, dataFromCSV[0].length)
                                  .map((d: any, n: number) => (
                                    <div
                                      role="td"
                                      className={styles.tableRowCell}
                                      key={n}
                                    >
                                      <span className={styles.data}>{d}</span>
                                    </div>
                                  ))}
                              </div>
                            </>
                          ) : windowSize.width > 960 ? (
                            <>
                              <div role="thead" className={styles.tableHeader}>
                                {dataFromCSV[0]
                                  .slice(4, dataFromCSV[0].length)
                                  .map((d: any, n: number) => (
                                    <div
                                      role="td"
                                      className={styles.tableRowCell}
                                      key={n}
                                    >
                                      <span className={styles.data}>{d}</span>
                                    </div>
                                  ))}
                              </div>
                              <div role="tr" className={styles.tableRow}>
                                {i
                                  .slice(4, dataFromCSV[0].length)
                                  .map((d: any, n: number) => (
                                    <div
                                      role="td"
                                      className={styles.tableRowCell}
                                      key={n}
                                    >
                                      <span className={styles.data}>{d}</span>
                                    </div>
                                  ))}
                              </div>
                            </>
                          ) : (
                            <>
                              <div role="thead" className={styles.tableHeader}>
                                {dataFromCSV[0]
                                  .slice(4, 7)
                                  .map((d: any, n: number) => (
                                    <div
                                      role="td"
                                      className={styles.tableRowCell}
                                      key={n}
                                    >
                                      <span className={styles.data}>{d}</span>
                                    </div>
                                  ))}
                              </div>
                              <div role="tr" className={styles.tableRow}>
                                {i.slice(4, 7).map((d: any, n: number) => (
                                  <div
                                    role="td"
                                    className={styles.tableRowCell}
                                    key={n}
                                  >
                                    <span className={styles.data}>{d}</span>
                                  </div>
                                ))}
                              </div>
                              <div role="thead" className={styles.tableHeader}>
                                {dataFromCSV[0]
                                  .slice(7, 11)
                                  .map((d: any, n: number) => (
                                    <div
                                      role="td"
                                      className={styles.tableRowCell}
                                      key={n}
                                    >
                                      <span className={styles.data}>{d}</span>
                                    </div>
                                  ))}
                              </div>
                              <div role="tr" className={styles.tableRow}>
                                {i.slice(7, 11).map((d: any, n: number) => (
                                  <div
                                    role="td"
                                    className={styles.tableRowCell}
                                    key={n}
                                  >
                                    <span className={styles.data}>{d}</span>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </div>
                      </motion.div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </GridElement>
    </GridWrapper>
  );
};

export default OOMLeaderboard;
