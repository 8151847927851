import { Fragment, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { motion } from "framer-motion";
import styles from "./ScoreCardVideo.module.css";

interface ScoreCardVideoProps {
  videoData: any;
  currentHoleNumber: number;
  currentShotNumber: number;
  setCurrentShotNumber: (currentShot: number) => void;
  setCurrentHoleNumber: (currentShot: number) => void;
  setCurrentVideo: (currentVideo: string | undefined) => void;
  isScoreCardOpen: boolean;
  playerMuted?: boolean;
}

const ScoreCardVideo = ({
  videoData,
  currentHoleNumber,
  currentShotNumber,
  setCurrentShotNumber,
  setCurrentHoleNumber,
  setCurrentVideo,
  isScoreCardOpen,
  playerMuted,
}: ScoreCardVideoProps) => {
  // @todo create custom controls for video player

  const [videoTotalTime, setVideoTotalTime] = useState<null | number>(null);
  const [videoCurrentTime, setVideoCurrentTime] = useState(0);
  const [playbackStarted, setPlaybackStarted] = useState(false);
  const [videoCompleted, setVideoCompleted] = useState(false);

  const playerRef = useRef<ReactPlayer | null>(null);

  const handleTimeUpdate = (e: any) => {
    const shots = videoData[currentHoleNumber - 1].shots;
    setVideoCurrentTime(e.playedSeconds);
    if (
      e.playedSeconds === videoTotalTime &&
      currentShotNumber < shots[shots.length - 1].shotNumber
    ) {
      setVideoCurrentTime(0);
      const shotIndex = shots.findIndex(
        (shot: any) => shot.shotNumber === currentShotNumber
      );

      if (shotIndex < shots.length) {
        setCurrentShotNumber(shots[shotIndex + 1].shotNumber);
      }
    }
    if (
      e.playedSeconds === videoTotalTime &&
      currentShotNumber === shots[shots.length - 1].shotNumber &&
      currentHoleNumber < 18
    ) {
      setCurrentHoleNumber(currentHoleNumber + 1);
      setVideoCurrentTime(0);
      setCurrentShotNumber(1);
      if (
        videoData[currentHoleNumber] &&
        videoData[currentHoleNumber].shots &&
        videoData[currentHoleNumber].shots[0].videos
      ) {
        setCurrentVideo(videoData[currentHoleNumber].shots[0].videos[0]);
      } else {
        setCurrentVideo(undefined);
      }
    }
  };

  const handleDurationChange = (duration: number) => {
    setVideoTotalTime(duration);
  };

  useEffect(() => {
    setVideoCurrentTime(0);
  }, [currentHoleNumber]);

  return videoData[currentHoleNumber - 1].shots.find(
    (shot: { shotNumber: number }) => shot.shotNumber === currentShotNumber
  ).videos[0]?.videoUrl ? (
    <div className={styles.videoContainer}>
      <ReactPlayer
        className={styles.reactPlayer}
        ref={playerRef}
        url={
          videoData[currentHoleNumber - 1].shots.find(
            (shot: { shotNumber: number }) =>
              shot.shotNumber === currentShotNumber
          ).videos[0]?.videoUrl
        }
        playing={isScoreCardOpen}
        muted={playerMuted}
        playsinline
        controls
        width="100%"
        height="100%"
        progressInterval={250}
        onProgress={handleTimeUpdate}
        onDuration={handleDurationChange}
        config={{
          file: {
            attributes: {
              onContextMenu: (e: any) => e.preventDefault(),
              controlsList: "nodownload noplaybackrate",
            },
          },
        }}
      />

      <div className={styles.videoNav}>
        {videoData[currentHoleNumber - 1].shots.map(
          (shot: { shotNumber: number }) => {
            return (
              <Fragment key={shot.shotNumber}>
                <div
                  className={`${styles.videoShotNumber}${
                    shot.shotNumber === currentShotNumber
                      ? " " + styles.videoShotNumberSelected
                      : ""
                  }`}
                  key={shot.shotNumber}
                  onClick={() => {
                    if (playbackStarted) {
                      setPlaybackStarted(false);
                    }
                    if (videoCompleted) {
                      setVideoCompleted(false);
                    }
                    setCurrentShotNumber(shot.shotNumber);
                    setVideoCurrentTime(0);
                    playerRef.current?.seekTo(0);
                  }}
                >
                  {shot.shotNumber}
                </div>
                <div className={styles.shotTimeline}>
                  {shot.shotNumber < currentShotNumber && (
                    <div
                      className={`${styles.shotTimelineStatus} ${styles.shotTimelineStatusComplete}`}
                    />
                  )}
                  {videoTotalTime && shot.shotNumber === currentShotNumber && (
                    <motion.div
                      className={styles.shotTimelineStatus}
                      initial={{ width: "0%" }}
                      animate={{
                        width: `${(videoCurrentTime / videoTotalTime) * 100}%`,
                        transition: {
                          type: "tween",
                        },
                      }}
                      exit={{ width: "100%" }}
                    />
                  )}
                </div>
                {shot.shotNumber ===
                  videoData[currentHoleNumber - 1].shots.slice(-1)[0]
                    .shotNumber && <div className={styles.videoShotNumber} />}
              </Fragment>
            );
          }
        )}
      </div>
    </div>
  ) : null;
};

export default ScoreCardVideo;
