import { storyblokEditable } from "@storyblok/react";
import { useState } from "react";
import { sbBlok } from "../../types/sb-types";
import styles from "./PrizePoolItem.module.css";

interface PrizePoolItemProps {
  content: sbBlok;
  inView: boolean;
  roundParticipants: number;
  roundPrizeTotal: number;
  roundPrizeSidebets?: number;
  isTest?: boolean;
  sidebets: sbBlok[];
  showBorder?: boolean;
  isFinal?: boolean;
}

const PrizePoolItem = ({
  inView,
  roundParticipants,
  roundPrizeTotal,
  isTest,
  sidebets,
  showBorder = true,
  content,
  roundPrizeSidebets,
  isFinal,
}: PrizePoolItemProps) => {
  const [inputVal, setInputVal] = useState("");
  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setInputVal(target.value);
  };

  const [testParticipants, setTestParticipants] = useState(10);
  const [testPrize, setTestPrize] = useState(65000 + 100 * 10);

  const prizeCalculator = () => {
    const N_PLAYERS = isTest ? testParticipants : roundParticipants;
    const TOTAL_PRIZE = isTest
      ? testPrize
      : roundPrizeTotal - (roundPrizeSidebets || 35000);
    const RATIO = 1.3;

    const prizeList = [];
    let prizeSum = 0;

    const remainder =
      TOTAL_PRIZE - (100 + 150 + 200 + 250 + 300 + 350) * N_PLAYERS * 0.05;

    for (let i = 0; i < N_PLAYERS; i++) {
      const RANK = i + 1;
      prizeList[i] = Math.round(
        ((RATIO - 1) * remainder) / Math.pow(RATIO, RANK)
      );
      const percentile = RANK / N_PLAYERS;
      if (percentile <= 0.05) prizeList[i] += 350;
      if (percentile > 0.05 && percentile <= 0.1) prizeList[i] += 300;
      if (percentile > 0.1 && percentile <= 0.15) prizeList[i] += 250;
      if (percentile > 0.15 && percentile <= 0.2) prizeList[i] += 200;
      if (percentile > 0.2 && percentile <= 0.25) prizeList[i] += 150;
      if (percentile > 0.25 && percentile <= 0.3) prizeList[i] += 100;
      if (percentile > 0.3) prizeList[i] = 0;
      if (i > 0 && prizeList[i] > 0) {
        prizeSum += prizeList[i];
      }
    }

    prizeList[0] = TOTAL_PRIZE - prizeSum;

    return prizeList;
  };

  const prizeListFinal = [
    20000, 12600, 8100, 6300, 4725, 3511, 3009, 2508, 2247, 2006, 1846, 1725,
    1615, 1540, 1475, 1414, 1354, 1294, 1244, 1204, 1164, 1134, 1103, 1073,
    1043, 1013, 983, 953, 923, 893,
  ];

  const prizeList = isFinal ? prizeListFinal : prizeCalculator();
  // const [testPrizeList, setTestPrizeList] = useState(prizeCalculator());

  return (
    <div
      className={`${styles.prizePool}${
        inView ? " " + styles.prizePoolActive : ""
      }`}
      {...storyblokEditable(content)}
    >
      <div className={styles.prizePoolTotal}>
        {showBorder && (
          <span
            className={`${styles.prizePoolBorder} ${styles.prizePoolBorderLeft}`}
          />
        )}
        <div className={styles.prizePoolTotalItem}>
          <div className={styles.totalItemTitle}>Purse</div>
          <div
            className={styles.totalItemInfo}
            style={{ transitionDelay: "0.1s" }}
          >
            ${roundPrizeTotal.toLocaleString("en-US")}
          </div>
        </div>
        <div className={styles.prizePoolTotalItem}>
          <div>Players</div>
          <div
            className={styles.totalItemInfo}
            style={{ transitionDelay: "0.2s" }}
          >
            {roundParticipants}
          </div>
        </div>
        <div
          className={styles.prizePoolTotalItem}
          style={{ marginTop: "4rem" }}
        >
          <div>Stroke play</div>
        </div>
        <div
          className={`${styles.prizePoolTotalItem} ${styles.prizePoolTotalItemGray}`}
        >
          <div>1st</div>
          <div
            className={styles.totalItemInfo}
            style={{ transitionDelay: "0.3s" }}
          >
            ${prizeList[0]?.toLocaleString("en-US")}
          </div>
        </div>
        <div
          className={`${styles.prizePoolTotalItem} ${styles.prizePoolTotalItemGray}`}
        >
          <div>2nd</div>
          <div
            className={styles.totalItemInfo}
            style={{ transitionDelay: "0.4s" }}
          >
            ${prizeList[1]?.toLocaleString("en-US") || 0}
          </div>
        </div>
        <div
          className={`${styles.prizePoolTotalItem} ${styles.prizePoolTotalItemGray}`}
        >
          <div>3rd</div>
          <div
            className={styles.totalItemInfo}
            style={{ transitionDelay: "0.5s" }}
          >
            ${prizeList[2]?.toLocaleString("en-US") || 0}
          </div>
        </div>
        <div
          className={`${styles.prizePoolTotalItem} ${styles.prizePoolTotalItemGray}`}
        >
          <div>4th</div>
          <div
            className={styles.totalItemInfo}
            style={{ transitionDelay: "0.6s" }}
          >
            ${prizeList[3]?.toLocaleString("en-US") || 0}
          </div>
        </div>
        <div
          className={`${styles.prizePoolTotalItem} ${styles.prizePoolTotalItemGray}`}
        >
          <div>5th</div>
          <div
            className={styles.totalItemInfo}
            style={{ transitionDelay: "0.7s" }}
          >
            ${prizeList[4]?.toLocaleString("en-US") || 0}
          </div>
        </div>
        <div
          className={styles.prizePoolTotalItem}
          style={{ margin: "4rem 0 2rem" }}
        >
          <div>
            <label>
              Your Rank
              <br />
              <input
                placeholder="Search for player"
                defaultValue={inputVal}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            $
            {prizeList[parseInt(inputVal) - 1]?.toLocaleString("en-US") || " 0"}
          </div>
        </div>
        <span
          className={`${styles.prizePoolBorder} ${styles.prizePoolBorderRight}`}
        />
      </div>
      <div className={styles.prizePoolSideKicks}>
        {sidebets?.map((c: sbBlok) => (
          <div key={c._uid} className={styles.sideKickItem}>
            <h3 className={styles.sideKickTitle}>{c.title}</h3>
            <div className={styles.sideKickItemInfo}>
              <span className={styles.sideKickItemRank}>1st</span>
              <span className={styles.sideKickItemPrice}>
                ${c.rank_1?.toLocaleString("en-US")}
              </span>
            </div>
            <div className={styles.sideKickItemInfo}>
              <span className={styles.sideKickItemRank}>2nd</span>
              <span className={styles.sideKickItemPrice}>
                ${c.rank_2?.toLocaleString("en-US")}
              </span>
            </div>
            <div className={styles.sideKickItemInfo}>
              <span className={styles.sideKickItemRank}>3rd</span>
              <span className={styles.sideKickItemPrice}>
                ${c.rank_3?.toLocaleString("en-US")}
              </span>
            </div>
            {c.rank_4 && (
              <div className={styles.sideKickItemInfo}>
                <span className={styles.sideKickItemRank}>4th</span>
                <span className={styles.sideKickItemPrice}>
                  ${c.rank_4?.toLocaleString("en-US")}
                </span>
              </div>
            )}
            {c.rank_5 && (
              <div className={styles.sideKickItemInfo}>
                <span className={styles.sideKickItemRank}>5th</span>
                <span className={styles.sideKickItemPrice}>
                  ${c.rank_5?.toLocaleString("en-US")}
                </span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrizePoolItem;
