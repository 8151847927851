import { ReactNode, useEffect, useRef, useState } from "react";
import styles from "./Dropdown.module.css";
import { motion } from "framer-motion";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";

export interface Option {
  label: string;
  id?: string | number;
}

interface DropdownProps {
  className?: string;
  defaultValue: Option | undefined;
  isCustomOptions?: boolean;
  options: Option[] | ReactNode;
  onChange?: (selectedOption: Option) => void;
}

const Dropdown = ({
  className,
  defaultValue,
  isCustomOptions = false,
  options,
  onChange,
}: DropdownProps) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useDetectOutsideClick({
    el: dropdownRef,
    initialState: false,
  });
  const [selected, setSelected] = useState<string>(defaultValue?.label || "");
  const [outerBorderRadius, setOuterBorderRadius] = useState(10);
  const [isArrowRotated, setIsArrowRotated] = useState(false);

  useEffect(() => {
    setIsArrowRotated(isOpen);
  }, [isOpen]);

  const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    setIsOpen(!isOpen);
  };

  const rootClasses = [styles.dropdown];
  if (className) rootClasses.push(className);
  const renderRootClasses = rootClasses.join(" ");

  return (
    <motion.div
      className={renderRootClasses}
      animate={{
        borderBottomLeftRadius: isOpen ? 0 : outerBorderRadius,
        borderBottomRightRadius: isOpen ? 0 : outerBorderRadius,
      }}
      initial={{
        borderBottomLeftRadius: outerBorderRadius,
        borderBottomRightRadius: outerBorderRadius,
      }}
      transition={{ ease: "linear", duration: 0.05 }}
      onClick={handleOpen}
    >
      <div className={styles.dropdownPlaceholder} ref={dropdownRef}>
        <div className={styles.dropdownItemMain}>{selected}</div>
        <motion.div
          animate={{
            scaleY: isArrowRotated ? -1 : 1,
            y: isArrowRotated ? -5 : 1,
          }}
          transition={{
            duration: 0.4,
          }}
          className={styles.dropdownIcon}
        >
          <ExpandMoreOutlinedIcon fontSize="large" />
        </motion.div>
      </div>
      <motion.nav
        initial={{ height: 0 }}
        animate={{ height: isOpen ? "auto" : 0 }}
        transition={{ duration: 0.4 }}
        onAnimationStart={() => isOpen && setOuterBorderRadius(0)}
        onAnimationComplete={() => !isOpen && setOuterBorderRadius(10)}
      >
        {isCustomOptions ? (
          (options as ReactNode)
        ) : (
          <>
            {(options as Option[])?.map((option: any, index: number) => (
              <div
                key={index}
                className={`${styles.dropdownItem} ${
                  option?.label === selected ? styles.dropdownItemActive : ""
                }`}
                onClick={() => {
                  setSelected(option?.label);
                  onChange && onChange(option);
                }}
              >
                {option.label}
              </div>
            ))}
          </>
        )}
      </motion.nav>
    </motion.div>
  );
};

export default Dropdown;
