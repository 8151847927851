import styles from "./Checkbox.module.css";

interface CheckboxProps {
  disabled?: boolean;
  label: string;
  value?: string | null;
  isChecked?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = ({
  disabled,
  label,
  value,
  isChecked,
  onChange,
}: CheckboxProps) => {
  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  return (
    <div>
      <label className={styles.checkboxControl}>
        <input
          type="checkbox"
          checked={isChecked}
          disabled={disabled}
          value={value || undefined}
          onChange={onCheckboxChange}
        />
        <span>{label}</span>
      </label>
    </div>
  );
};

export default Checkbox;
