import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { LeaderboardTournament } from "../../queries/course-tournament";
import { TournamentBirdieStreak } from "../../queries/tournament-birdie-streak";
import { TournamentClosestToPin } from "../../queries/tournament-closest-to-pin";
import { TournamentGreensInRegulation } from "../../queries/tournament-gir";
import { TournamentLeaderboard } from "../../queries/tournament-leaderboard";
import { TournamentLongestDrive } from "../../queries/tournament-longest-drive";
import { GOLF_PORTAL_API } from "../../utils/constants";
import StrokeplayLeaderboard from "./StrokeplayLeaderboard";
import CTPLeaderboard from "./CTPLeaderboard";
import LongestDriveLeaderboard from "./LongestDriveLeaderboard";
import BirdieStreakLeaderboard from "./BirdieStreakLeadearboard";
import GIRLeaderboard from "./GIRLeaderboard";
import styles from "./Leaderboard.module.css";
import { amLeaderboard } from "../../queries/am-course-tournament";
import { TournamentClosestToPinAM } from "../../queries/am-tournament-closest-to-pin";
import CTPHoleLeaderboard from "./CTPHoleLeaderboard";
import Cta from "../Cta/Cta";
import {
  QueryVariablesType,
  TournamentQuery,
} from "../LeaderboardWrapper/LeaderboardWrapper";

const TOURNAMENT_QUERY = {
  amLeaderboard,
  leaderboard: TournamentLeaderboard,
  aggregatedClosestToPinLeaderboard: TournamentClosestToPin,
  closestToPinLeaderboard: TournamentClosestToPinAM,
  birdieStreak: TournamentBirdieStreak,
  longestDrive: TournamentLongestDrive,
  greensInRegulation: TournamentGreensInRegulation,
};

interface LeaderboardProps {
  tournamentQueryKey: string | number;
  tournamentId: string;
  tournamentQuery: TournamentQuery;
  queryVariables?: QueryVariablesType;
  tournamentName?: string;
  isAmateur?: boolean;
}

const Leaderboard = ({
  tournamentQueryKey,
  tournamentId,
  tournamentQuery,
  queryVariables,
  tournamentName,
  isAmateur,
}: LeaderboardProps) => {
  const [selectedPlayers, setSelectedPlayers] = useState<string[]>([]);
  const [favouritesList, setFavouritesList] = useState<string[]>([]);
  const [tournamentRoundId, setTournamentRoundId] = useState(null);
  const [longestDriveHole, setLongestDriveHole] = useState(null);

  const { asPath } = useRouter();

  const { data: tournamentData, isLoading: tournamentDataLoading } = useQuery(
    ["tournament", tournamentId],
    () => {
      return axios.post(GOLF_PORTAL_API, {
        query: LeaderboardTournament,
        variables: {
          tournamentId,
        },
      });
    },
    {
      enabled: !asPath.includes("playerid"),
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const roundId = data?.data?.data?.node?.rounds[0]?.id;
        const hole =
          data?.data?.data?.node?.rounds[0]?.embeddedGame?.longestDrive?.holes;
        const holeNumber = hole && hole[0];

        setTournamentRoundId(roundId);
        setLongestDriveHole(holeNumber);
      },
    }
  );

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
    isRefetching,
    isError,
  } = useInfiniteQuery(
    [
      "tournament_leaderboard",
      tournamentQueryKey,
      queryVariables,
      tournamentRoundId,
      longestDriveHole,
    ],
    ({ pageParam = 0 }) => {
      return axios.post(GOLF_PORTAL_API, {
        query: TOURNAMENT_QUERY[tournamentQuery as keyof {}],
        variables: {
          holeNumber: longestDriveHole,
          playerIds: favouritesList,
          tournamentId,
          publishedTournamentId: tournamentId,
          roundId: tournamentRoundId,
          skip: pageParam === 0 ? 0 : 50,
          take: pageParam === 0 ? 50 : 8000,
          ...queryVariables,
        },
      });
    },
    {
      enabled: !tournamentDataLoading,
      refetchOnWindowFocus: false,
      getNextPageParam: (_lastPage, pages) => {
        const dataNode = pages[pages.length - 1].data.data.node;
        if (
          ((tournamentQuery === "leaderboard" ||
            tournamentQuery === "amLeaderboard") &&
            dataNode?.leaderboard?.records?.pageInfo?.hasNextPage) ||
          (tournamentQuery === "aggregatedClosestToPinLeaderboard" &&
            dataNode?.aggregatedClosestToPinLeaderboard?.records?.pageInfo
              ?.hasNextPage) ||
          ((tournamentQuery === "birdieStreak" ||
            tournamentQuery === "greensInRegulation") &&
            dataNode?.otherLeaderboards?.records?.pageInfo?.hasNextPage) ||
          ((tournamentQuery === "longestDrive" ||
            tournamentQuery === "closestToPinLeaderboard") &&
            dataNode?.embeddedGameLeaderboard?.records?.pageInfo?.hasNextPage)
        ) {
          return pages.length;
        } else {
          return undefined;
        }
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [favouritesList]);

  let totalCount;
  let currentCount;

  if (
    tournamentQuery === "leaderboard" ||
    tournamentQuery === "amLeaderboard"
  ) {
    totalCount =
      data?.pages?.[0]?.data?.data?.node?.leaderboard?.records?.totalCount;
    currentCount =
      data?.pages?.[0]?.data?.data?.node?.leaderboard?.records?.items?.length;
  }
  if (tournamentQuery === "aggregatedClosestToPinLeaderboard") {
    totalCount =
      data?.pages?.[0]?.data?.data?.node?.aggregatedClosestToPinLeaderboard
        ?.records?.totalCount;
    currentCount =
      data?.pages?.[0]?.data?.data?.node?.aggregatedClosestToPinLeaderboard
        ?.records?.items?.length;
  }
  if (tournamentQuery === "birdieStreak") {
    totalCount =
      data?.pages?.[0]?.data?.data?.node?.otherLeaderboards?.records
        ?.totalCount;
    currentCount =
      data?.pages?.[0]?.data?.data?.node?.otherLeaderboards?.records?.items
        ?.length;
  }
  if (
    tournamentQuery === "longestDrive" ||
    tournamentQuery === "closestToPinLeaderboard"
  ) {
    totalCount =
      data?.pages?.[0]?.data?.data?.node?.embeddedGameLeaderboard?.records
        ?.totalCount;
    currentCount =
      data?.pages?.[0]?.data?.data?.node?.embeddedGameLeaderboard?.records
        ?.items?.length;
  }
  if (tournamentQuery === "greensInRegulation") {
    totalCount =
      data?.pages?.[0]?.data?.data?.node?.otherLeaderboards?.records
        ?.totalCount;
    currentCount =
      data?.pages?.[0]?.data?.data?.node?.otherLeaderboards?.records?.items
        ?.length;
  }

  const loading = isLoading || isFetchingNextPage || isRefetching;

  const Error = () => {
    return isError ? (
      <h2 className={styles.infoHeading}>
        Oops.. something went wrong. Please try again later.
      </h2>
    ) : null;
  };

  return (
    <div className={styles.leaderboardTableWrapper}>
      {(tournamentQuery === "leaderboard" ||
        tournamentQuery === "amLeaderboard") && (
        <StrokeplayLeaderboard
          data={data}
          gender={queryVariables?.gender}
          isLoading={loading}
          ErrorMessage={Error}
          favouritesList={favouritesList}
          setFavouritesList={setFavouritesList}
          refetchFavourites={refetch}
          favoritePlayers={
            data?.pages[0]?.data?.data?.node?.leaderboard?.selectedPlayers
          }
          tournamentName={tournamentName}
          isAmateur={isAmateur}
        />
      )}
      {tournamentQuery === "aggregatedClosestToPinLeaderboard" && (
        <CTPLeaderboard
          tournamentData={tournamentData}
          data={data}
          gender={queryVariables?.gender}
          searchText={queryVariables?.searchText}
          selectedPlayers={selectedPlayers}
          setSelectedPlayers={setSelectedPlayers}
          isLoading={loading}
          ErrorMessage={Error}
        />
      )}
      {tournamentQuery === "closestToPinLeaderboard" && (
        <CTPHoleLeaderboard
          data={data}
          gender={queryVariables?.gender}
          searchText={queryVariables?.searchText}
          selectedPlayers={selectedPlayers}
          setSelectedPlayers={setSelectedPlayers}
          isLoading={loading}
          ErrorMessage={Error}
        />
      )}
      {tournamentQuery === "longestDrive" && (
        <LongestDriveLeaderboard
          data={data}
          isLoading={loading}
          ErrorMessage={Error}
        />
      )}
      {tournamentQuery === "birdieStreak" && (
        <BirdieStreakLeaderboard
          data={data}
          isLoading={loading}
          ErrorMessage={Error}
        />
      )}
      {tournamentQuery === "greensInRegulation" && (
        <GIRLeaderboard data={data} isLoading={loading} ErrorMessage={Error} />
      )}
      <div className={styles.paginationContainer}>
        <p>
          Showing{" "}
          {loading ? (
            <span
              className={styles.loading}
              style={{ background: "#ccc", width: "3rem", height: "1.8rem" }}
            />
          ) : hasNextPage ? (
            currentCount
          ) : (
            totalCount
          )}{" "}
          from {totalCount}
        </p>
      </div>
      {hasNextPage && (
        <Cta
          style={{ margin: "4rem auto 0" }}
          onClick={() => fetchNextPage()}
          disabled={loading}
          name="Show all"
        />
      )}
    </div>
  );
};

export default Leaderboard;
