import { motion } from "framer-motion";
import CountryFlag from "react-country-flag";
import { InfiniteData } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useWindowSize } from "../../hooks/useWindowSize";
import LeaderboardLoading from "./LeaderboardLoading";
import { groupInnerVariants } from "./animations";
import styles from "./Leaderboard.module.css";

interface CTPlayerData {
  playerId: string;
  playername: string;
  gender: string;
  nationality?: string;
  total: {
    distanceToPin: number;
    pos: number;
  };
  holes: [{ holeNumber: number; distanceToPin: number }];
}

interface CTPLeaderboardData {
  data: {
    node: {
      aggregatedClosestToPinLeaderboard: {
        records: {
          items: [CTPlayerData];
        };
      };
    };
  };
}

interface TournamentData {
  data: {
    node: {
      rounds: [
        {
          embeddedGame: {
            closestToPin: {
              holes: [number];
            };
          };
        }
      ];
    };
  };
}

interface CTPLeaderboardProps {
  gender?: string;
  selectedPlayers: string[];
  setSelectedPlayers: (ids: string[]) => void;
  tournamentData: undefined | AxiosResponse<TournamentData, any>;
  data: undefined | InfiniteData<AxiosResponse<CTPLeaderboardData, any>>;
  isLoading: boolean;
  searchText?: string;
  ErrorMessage: () => JSX.Element | null;
}

const CTPLeaderboard = ({
  tournamentData,
  data,
  gender,
  selectedPlayers,
  setSelectedPlayers,
  isLoading,
  searchText,
  ErrorMessage,
}: CTPLeaderboardProps) => {
  const { windowSize } = useWindowSize();
  return (
    <div
      role="table"
      className={`${styles.leaderboardTable} ${styles.leaderboardTableCTP}`}
    >
      <div role="thead" className={styles.tableHeader}>
        <div role="th" className={styles.tableHeaderCell}>
          Pos
        </div>
        <div role="th" className={styles.tableHeaderCell}>
          <span className={styles.thCellLabel}>Country</span>
        </div>
        <div role="th" className={styles.tableHeaderCell}>
          Player
        </div>
        {windowSize.width > 860 &&
          tournamentData?.data?.data?.node?.rounds[0]?.embeddedGame?.closestToPin?.holes?.map(
            (hole: number) => (
              <div role="th" className={styles.tableHeaderCell} key={hole}>
                Hole {hole}
              </div>
            )
          )}
        <div role="th" className={styles.tableHeaderCell}>
          Total
        </div>
      </div>
      <div role="tbody" className={styles.tableBody}>
        <ErrorMessage />

        {data?.pages?.map((page: { data: CTPLeaderboardData }, x: number) => {
          const itemsLength =
            page.data.data.node.aggregatedClosestToPinLeaderboard?.records
              ?.items.length;

          if (x === 0 && !itemsLength && !searchText) {
            return (
              <h2 key={x} className={styles.infoHeading}>
                No players have hit all the required greens yet
              </h2>
            );
          }

          if (x === 0 && !itemsLength && searchText) {
            return (
              <h2 key={x} className={styles.infoHeading}>
                No results found
              </h2>
            );
          }

          return page.data.data.node.aggregatedClosestToPinLeaderboard?.records?.items.map(
            (player: CTPlayerData) => {
              const convertMToFeet = (distanceInMeters: number) => {
                const inches = distanceInMeters * 39.37;
                const feet = Math.floor(inches / 12);
                const remainingInches = Math.floor(inches % 12);
                return `${feet}' ${remainingInches}"`;
              };
              return (
                <div
                  role="tr"
                  className={`${styles.tableRowGroup} ${
                    styles.tableRowGroupCTP
                  }${
                    gender &&
                    player.gender &&
                    player.gender.toLowerCase() !== gender.toLowerCase()
                      ? " " + styles.tableRowGroupHidden
                      : ""
                  }${
                    selectedPlayers.includes(player.playerId)
                      ? " " +
                        styles.tableRowGroupActive +
                        " " +
                        styles.tableRowGroupActiveCTP
                      : ""
                  }`}
                  key={player.playerId}
                >
                  <div
                    role="tr"
                    className={styles.tableRow}
                    onClick={() => {
                      if (
                        windowSize.width > 860 ||
                        (gender &&
                          player.gender &&
                          player.gender.toLowerCase() !== gender.toLowerCase())
                      )
                        return;
                      if (selectedPlayers.includes(player.playerId)) {
                        setSelectedPlayers(
                          selectedPlayers.filter(
                            (id: string) => id !== player.playerId
                          )
                        );
                        return;
                      }
                      if (player.playerId) {
                        setSelectedPlayers([
                          ...selectedPlayers,
                          player.playerId,
                        ]);
                      }
                    }}
                  >
                    <div role="td" className={styles.tableRowCell}>
                      {player.total.pos}
                    </div>
                    <div role="td" className={styles.tableRowCell}>
                      {player.nationality && (
                        <CountryFlag
                          countryCode={player.nationality}
                          style={{
                            fontSize: windowSize.width < 960 ? "1.6em" : "2em",
                          }}
                          svg
                        />
                      )}
                    </div>
                    <div role="td" className={styles.tableRowCell}>
                      {player.playername}
                    </div>
                    {windowSize.width > 860 &&
                      player.holes.map((hole) => (
                        <div
                          role="td"
                          className={styles.tableRowCell}
                          key={hole.holeNumber}
                        >
                          {convertMToFeet(hole.distanceToPin)}
                        </div>
                      ))}
                    <div role="td" className={styles.tableRowCell}>
                      {convertMToFeet(player.total.distanceToPin)}
                    </div>
                  </div>
                  <motion.div
                    role="tr"
                    className={styles.tableRowGroupInner}
                    variants={groupInnerVariants}
                    initial="initial"
                    animate={
                      selectedPlayers.includes(player.playerId)
                        ? "open"
                        : "initial"
                    }
                    exit="initial"
                  >
                    <div>
                      <div role="thead" className={styles.tableHeader}>
                        {windowSize.width < 861 &&
                          tournamentData?.data.data.node.rounds[0].embeddedGame.closestToPin.holes.map(
                            (hole: number) => (
                              <div
                                role="th"
                                className={styles.tableHeaderCell}
                                key={hole}
                              >
                                Hole {hole}
                              </div>
                            )
                          )}
                      </div>
                      <div role="tr" className={styles.tableRow}>
                        {windowSize.width < 861 &&
                          player.holes.map((hole) => (
                            <div
                              role="td"
                              className={styles.tableRowCell}
                              key={hole.holeNumber}
                            >
                              {convertMToFeet(hole.distanceToPin)}
                            </div>
                          ))}
                      </div>
                    </div>
                  </motion.div>
                </div>
              );
            }
          );
        })}
        {isLoading && <LeaderboardLoading columns={6} />}
      </div>
    </div>
  );
};

export default CTPLeaderboard;
