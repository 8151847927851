export const TournamentLeaderboard = `query TournamentLeaderboard(
  $tournamentId: ID!,
  $skip: Int,
  $take: Int,
  $groupId: String,
  $playerIds: [ID!]!,
  $gender: Gender,
  $nationality: [String!],
  $playNow: Boolean,
  $searchText: String
) {
  node(id: $tournamentId) {
    ... on CourseTournament {
      leaderboard(participantGroupId: $groupId) {
        records(nationality: $nationality, playNow: $playNow, gender: $gender, searchText: $searchText, skip: $skip, take: $take) {
          items {
            ... LeaderboardPlayer
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
        selectedPlayers(ids: $playerIds) {
          ... LeaderboardPlayer
        }
      }
    }
  }
}

fragment LeaderboardPlayer on LeaderboardRecord
{
  playername
  id
  playerId
  picture
  nationality
  gender
  hcp
  category
  total {
    ... LeaderboardTotal
  }
  latestRound {
    ... LeaderboardRound
  }
  rounds {
    ... LeaderboardRound
  }
  status
}

fragment LeaderboardTotal on LeaderboardTotalScore
{
  pos
  posLabel
  score
  state
  toPar
  thru
}

fragment LeaderboardRound on LeaderboardRoundScoreType
{
  scorecardId
  pos
  posLabel
  score
  state
  roundNumber
  roundState
  thru
  toPar
  scorecard {
    course {
      displayName
    }
  }
}`;

export const TournamentLeaderboardWidget = `query TournamentLeaderboardWidget(
  $tournamentId: ID!,
  $skip: Int,
  $take: Int,
) {
  node(id: $tournamentId) {
    ... on CourseTournament {
      leaderboard() {
        records(skip: $skip, take: $take) {
          items {
            ... on LeaderboardRecord
            {
              playername
              id
              hcp
              total {
                  pos
                  posLabel
                  score
                  state
                  toPar
                  thru
              }
            }
          }
        }
      }
    }
  }
}`;
