import { motion } from "framer-motion";
import CountryFlag from "react-country-flag";
import { InfiniteData } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useWindowSize } from "../../hooks/useWindowSize";
import LeaderboardLoading from "./LeaderboardLoading";
import { groupInnerVariants } from "./animations";
import styles from "./Leaderboard.module.css";

interface CTPlayerData {
  id: string;
  playername: string;
  gender: string;
  nationality?: string;
  score: {
    distanceToPin: number;
    pos: number;
  };
  holes: [{ holeNumber: number; distanceToPin: number }];
}

interface CTPHoleLeaderboardData {
  data: {
    node: {
      embeddedGameLeaderboard: {
        records: {
          items: [CTPlayerData];
        };
      };
    };
  };
}

interface CTPHoleLeaderboardProps {
  gender?: string;
  selectedPlayers: string[];
  setSelectedPlayers: (ids: string[]) => void;
  data: undefined | InfiniteData<AxiosResponse<CTPHoleLeaderboardData, any>>;
  isLoading: boolean;
  searchText?: string;
  ErrorMessage: () => JSX.Element | null;
}

const CTPHoleLeaderboard = ({
  data,
  gender,
  isLoading,
  searchText,
  ErrorMessage,
}: CTPHoleLeaderboardProps) => {
  const { windowSize } = useWindowSize();
  return (
    <div
      role="table"
      className={`${styles.leaderboardTable} ${styles.leaderboardTableCTP}`}
    >
      <div role="thead" className={styles.tableHeader}>
        <div role="th" className={styles.tableHeaderCell}>
          Pos
        </div>
        <div role="th" className={styles.tableHeaderCell}>
          <span className={styles.thCellLabel}>Country</span>
        </div>
        <div role="th" className={styles.tableHeaderCell}>
          Player
        </div>
        <div role="th" className={styles.tableHeaderCell}>
          Total
        </div>
      </div>
      <div role="tbody" className={styles.tableBody}>
        <ErrorMessage />

        {data?.pages?.map(
          (page: { data: CTPHoleLeaderboardData }, x: number) => {
            const itemsLength =
              page.data.data.node.embeddedGameLeaderboard?.records?.items
                ?.length;

            if (x === 0 && !itemsLength && !searchText) {
              return (
                <h2 key={x} className={styles.infoHeading}>
                  No players have hit the required green yet
                </h2>
              );
            }

            if (x === 0 && !itemsLength && searchText) {
              return (
                <h2 key={x} className={styles.infoHeading}>
                  No results found
                </h2>
              );
            }

            return page.data.data.node.embeddedGameLeaderboard?.records?.items.map(
              (player: CTPlayerData) => {
                const convertMToFeet = (distanceInMeters: number) => {
                  const inches = distanceInMeters * 39.37;
                  const feet = Math.floor(inches / 12);
                  const remainingInches = Math.floor(inches % 12);
                  return `${feet}' ${remainingInches}"`;
                };
                return (
                  <div
                    role="tr"
                    className={`${styles.tableRowGroup} ${
                      styles.tableRowGroupCTP
                    }${
                      gender &&
                      player.gender &&
                      player.gender.toLowerCase() !== gender.toLowerCase()
                        ? " " + styles.tableRowGroupHidden
                        : ""
                    }`}
                    key={player.id}
                  >
                    <div role="tr" className={styles.tableRow}>
                      <div role="td" className={styles.tableRowCell}>
                        {player.score.pos}
                      </div>
                      <div role="td" className={styles.tableRowCell}>
                        {player.nationality && (
                          <CountryFlag
                            countryCode={player.nationality}
                            style={{
                              fontSize:
                                windowSize.width < 960 ? "1.6em" : "2em",
                            }}
                            svg
                          />
                        )}
                      </div>
                      <div role="td" className={styles.tableRowCell}>
                        {player.playername}
                      </div>
                      <div role="td" className={styles.tableRowCell}>
                        {convertMToFeet(player.score.distanceToPin)}
                      </div>
                    </div>
                  </div>
                );
              }
            );
          }
        )}
        {isLoading && <LeaderboardLoading columns={4} />}
      </div>
    </div>
  );
};

export default CTPHoleLeaderboard;
