import Image from "next/image";
import { sbBlok } from "../../types/sb-types";
import styles from "./Partners.module.css";
import { storyblokEditable } from "@storyblok/react";
import GridWrapper from "../GridWrapper/GridWrapper";
import GridElement from "../GridElement/GridElement";

const Partners = ({ blok }: sbBlok) => {
  const renderImageRows = (images: Array<sbBlok>) => {
    if (images.length > 3) {
      return (
        <div className={styles.imgContainer}>
          <div className={styles.imgRow}>
            {images.slice(0, 3).map((image: sbBlok) => (
              <div className={styles.imgWrapper} key={image._uid}>
                <Image
                  className={styles.img}
                  src={image.image_details.filename}
                  alt={image.image_details.alt}
                  layout="fill"
                  objectFit="cover"
                />
              </div>
            ))}
          </div>
          <div className={styles.imgRow}>
            {images.slice(3).map((image: sbBlok) => (
              <div className={styles.imgWrapper} key={image._uid}>
                <Image
                  className={styles.img}
                  src={image.image_details.filename}
                  alt={image.image_details.alt}
                  layout="fill"
                  objectFit="cover"
                />
              </div>
            ))}
          </div>
        </div>
      );
    }
    return (
      <div className={styles.imgContainer}>
        {images.map((image: sbBlok) => (
          <div className={styles.imgWrapper} key={image._uid}>
            <Image
              className={styles.img}
              src={image.image_details.filename}
              alt={image.image_details.alt}
              layout="fill"
              objectFit="cover"
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <section {...storyblokEditable(blok)}>
      <GridWrapper className={styles.gridWrapper}>
        <GridElement
          gridLayout={[
            [961, 2, 14, 1],
            [1401, 4, 26, 1],
          ]}
        >
          <h1 className={styles.title}>{blok.title}</h1>
          {renderImageRows(blok.images)}
        </GridElement>
      </GridWrapper>
    </section>
  );
};

export default Partners;
