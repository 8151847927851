import { ReactNode } from "react";
import styles from "./GridWrapper.module.css";

export enum Spacing {
  large = "large",
  x_large = "x_large",
  xx_large = "xx_large",
}

const spacingMap = {
  [Spacing.large]: "L",
  [Spacing.x_large]: "XL",
  [Spacing.xx_large]: "XXL",
};
interface GridWrapperProps {
  children: ReactNode;
  style?: React.CSSProperties;
  className?: string;
  gridClassName?: string;
  padding_top?: Spacing;
  padding_bottom?: Spacing;
  margin_top?: Spacing;
  margin_bottom?: Spacing;
}

const GridWrapper = ({
  children,
  style,
  className,
  gridClassName,
  padding_top,
  padding_bottom,
  margin_top,
  margin_bottom,
}: GridWrapperProps) => {
  const paddingTopClass = padding_top
    ? `paddingTop${spacingMap[padding_top]}`
    : "";
  const paddingBottomClass = padding_bottom
    ? `paddingBottom${spacingMap[padding_bottom]}`
    : "";
  const marginTopClass = margin_top ? `marginTop${spacingMap[margin_top]}` : "";
  const marginBottomClass = margin_bottom
    ? `marginBottom${spacingMap[margin_bottom]}`
    : "";

  const rootClasses = [styles.gridWrapper];

  if (className) rootClasses.push(className);

  if (padding_top) rootClasses.push(styles[paddingTopClass]);
  if (padding_bottom) rootClasses.push(styles[paddingBottomClass]);
  if (margin_top) rootClasses.push(styles[marginTopClass]);
  if (margin_bottom) rootClasses.push(styles[marginBottomClass]);

  const renderRootClasses = rootClasses.join(" ");

  return (
    <section style={style} className={renderRootClasses}>
      <div
        className={`${styles.grid}${gridClassName ? " " + gridClassName : ""}`}
      >
        {children}
      </div>
    </section>
  );
};

export default GridWrapper;
