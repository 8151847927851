export const amLeaderboard = `query coursePlayLeaderboard($publishedTournamentId: ID!, $playerIds: [ID!]!, $gender: Gender, $skip: Int, $take: Int, $participantGroupId: String) {
    node(id: $publishedTournamentId) {
      ... on CourseTournament {
        dbId
        settings {
          gameType
        }
        name
        startDate
        endDate
        hasOverlappingRounds
        tournamentState
        leaderboard(participantGroupId: $participantGroupId, gender: $gender) {
          selectedPlayers(ids: $playerIds) {
            email
            gender
            hcp
            id
            playername
            playerId
            nationality
            ...LeaderboardPlayerRounds
            ...LeaderboardPlayerTotalScore
          }
          records(skip: $skip, take: $take) {
            items {
              id
              playerId
              playername
              gender
              hcp
              nationality
              ...LeaderboardPlayerRounds
              ...LeaderboardPlayerTotalScore
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            totalCount
          }
        }
        rounds {
          roundNumber
          id
          roundDuration
          startDate
          endDate
          roundState
          embeddedGame {
            closestToPin {
              availableHoles
            }
          }
          course {
            displayName
            courseLocation
          }
        }
      }
    }
  }
  
  fragment LeaderboardPlayerRounds on LeaderboardRecord {
    rounds {
      roundNumber
      roundState
      state
      pos
      score
      toPar
      thru
      scorecardId
      scorecardDbId
      posLabel
      isCountingScore
    }
    latestRound {
      roundState
      pos
      posLabel
      roundNumber
      score
      scorecardDbId
      scorecardId
      state
      thru
      toPar
      isCountingScore
    }
  }
  
  
  fragment LeaderboardPlayerTotalScore on LeaderboardRecord {
    total {
      posLabel
      pos
      score
      state
      toPar
      thru
    }
  }
`;
