import { useState, useEffect } from "react";

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<
    undefined | { width: number; height: number }
  >(undefined);
  useEffect(() => {
    let timeout: number;
    const handleResize = () => {
      clearTimeout(timeout);
      timeout =
        (setWindowSize({
          width:
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth,
          height:
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight,
        }),
        800);
    };
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    return () => {
      clearTimeout(timeout);
      return window.removeEventListener("resize", handleResize);
    };
  }, []);
  return {
    windowSize: windowSize || { width: 0, height: 0 },
    isMobile: windowSize && windowSize.width > 0 && windowSize.width <= 809,
    isTablet: windowSize && windowSize.width > 809 && windowSize.width <= 1200,
    isDesktop: windowSize && windowSize.width > 1200,
  };
};
