import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import { useEffect, useState } from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
import { sbBlok } from "../../types/sb-types";
import GridElement from "../GridElement";
import GridWrapper from "../GridWrapper";
import styles from "./HeroHeader.module.css";

interface HeroHeaderProps {
  blok: sbBlok;
}

const HeroHeader = ({ blok }: HeroHeaderProps) => {
  const { bg_image_1, bg_image_2, image_headline } = blok;
  const { windowSize } = useWindowSize();
  let bgImageWidth_1 = 1662;
  let bgImageheight_1 = 881;
  let bgImageWidth_2 = 690;
  let bgImageheight_2 = 1028;
  // if (windowSize.height < 1400) {
  //   bgimageWidth_1 = 830;
  //   bgimageheight_1 = 1100;
  //   bgimageWidth_2 = 830;
  //   bgimageheight_2 = 1100;
  // }
  // if (windowSize.height < 1200) {
  //   bgimageWidth_1 = 630;
  //   bgimageheight_1 = 890;
  //   bgimageWidth_2 = 630;
  //   bgimageheight_2 = 890;
  // }
  // if (windowSize.height < 1000) {
  //   bgimageWidth_1 = 500;
  //   bgimageheight_1 = 760;
  //   bgimageWidth_2 = 500;
  //   bgimageheight_2 = 760;
  // }
  if (windowSize.width < 850) {
    bgImageWidth_1 = 430;
    bgImageheight_1 = 600;
    bgImageWidth_2 = 335;
    bgImageheight_2 = 500;
  }

  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section {...storyblokEditable(blok)} className={styles.heroHeader}>
      <GridWrapper
        className={`${styles.bgLayer}`}
        gridClassName={styles.bgLayerGrid_1}
        style={{ transform: `translateY(-${offsetY * 0.1}px)` }}
      >
        <GridElement>
          <div
            style={{
              height: windowSize.width < 850 ? "40vh" : "60vh",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Image
              src={bg_image_1.filename}
              alt={bg_image_1.alt}
              layout={windowSize.width < 850 ? "responsive" : "fixed"}
              width={bgImageWidth_1}
              height={bgImageheight_1}
              objectFit={windowSize.width < 850 ? "cover" : "unset"}
            />
          </div>
        </GridElement>
      </GridWrapper>
      <GridWrapper
        className={`${styles.bgLayer}`}
        gridClassName={styles.bgLayerGrid_2}
        style={{ transform: `translateY(-${offsetY * 0.2}px)` }}
      >
        <GridElement
          gridLayout={[
            [961, 3, 6],
            [1401, 6, 12],
          ]}
        >
          <div
            style={{
              height: "70vh",
              position: "relative",
            }}
          >
            <Image
              src={bg_image_2.filename}
              alt={bg_image_2.alt}
              layout="fixed"
              width={bgImageWidth_2}
              height={bgImageheight_2}
            />
          </div>
        </GridElement>
      </GridWrapper>
      <div
        className={styles.heroHeaderBgGraphics}
        style={{ transform: `translateY(${offsetY * 0.2}px)` }}
      />
      <GridWrapper
        style={{
          position: "absolute",
          width: "100%",
          top: "65%",
          left: 0,
          margin: "auto",
          transform: "translateY(-50%)",
        }}
      >
        <div className={styles.imgHeadline}>
          {image_headline?.filename && (
            <Image
              src={image_headline.filename}
              alt={image_headline.alt}
              layout="fixed"
              width={windowSize.width < 600 ? 300 : 500}
              height={windowSize.width < 600 ? 120 : 220}
              objectFit="contain"
            />
          )}
        </div>
        {blok.headline && <h3 className={styles.headline}>{blok.headline}</h3>}
        <div className={styles.scrollIcon}>
          <Image
            src={"/icons/arrow_down.png"}
            sizes="100vw"
            alt="range icon"
            layout="fill"
            objectFit="contain"
          />
        </div>
      </GridWrapper>
    </section>
  );
};

export default HeroHeader;
