import { storyblokEditable } from "@storyblok/react";
import ReactCountdown from "react-countdown";
import { sbBlok } from "../../types/sb-types";
import Cta from "../Cta";
import GridElement from "../GridElement";
import GridWrapper from "../GridWrapper";
import styles from "./Countdown.module.css";

type CountdownVariantProps = {
  variant: "right" | "left" | "center";
};

const renderer = ({ formatted: { days, hours, minutes, seconds } }: any) => {
  return (
    <div className={styles.countdown}>
      <div className={styles.countdownTime}>
        <div className={styles.timeContainer}>
          <div className={styles.timeValue} suppressHydrationWarning>
            {days}
          </div>
          <div className={styles.timeUnit}>days</div>
        </div>
      </div>
      <div className={styles.countdownTime}>
        <div className={styles.timeContainer}>
          <div className={styles.timeValue} suppressHydrationWarning>
            {hours}
          </div>
          <div className={styles.timeUnit}>hours</div>
        </div>
      </div>
      <div className={styles.countdownTime}>
        <div className={styles.timeContainer}>
          <div className={styles.timeValue} suppressHydrationWarning>
            {minutes}
          </div>
          <div className={styles.timeUnit}>min</div>
        </div>
      </div>
      <div className={styles.countdownTime}>
        <div className={styles.timeContainer}>
          <div className={styles.timeValue} suppressHydrationWarning>
            {seconds}
          </div>
          <div className={styles.timeUnit}>sec</div>
        </div>
      </div>
    </div>
  );
};

const Countdown = ({ blok }: sbBlok) => {
  const { countdown_end_time, title, variant, cta_button } = blok;

  return (
    <GridWrapper
      className={`${styles.countdownGridWrapper} ${
        styles[`countdownVariant--${variant as CountdownVariantProps}`]
      }`}
    >
      <GridElement
        className={
          styles[`countdownGridElement--${variant as CountdownVariantProps}`]
        }
      >
        <div
          className={`${styles.countdownContainer} ${
            styles[`countdownContainer--${variant as CountdownVariantProps}`]
          }`}
          {...storyblokEditable(blok)}
        >
          <span className={styles.countdownTitle}>{title}</span>
          <div className={styles.countdownWrapper}>
            <ReactCountdown
              renderer={(props) => renderer({ ...props })}
              date={countdown_end_time}
            />
            {cta_button?.length > 0 && (
              <Cta
                href={cta_button[0].link.url}
                fullSlug={cta_button[0].link.story?.full_slug}
                name={cta_button[0].name}
                className={styles.ctaButton}
              />
            )}
          </div>
        </div>
      </GridElement>
    </GridWrapper>
  );
};

export default Countdown;
