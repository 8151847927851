import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useWindowSize } from "../../hooks/useWindowSize";
import { sbBlok } from "../../types/sb-types";
import GridElement from "../GridElement";
import GridWrapper from "../GridWrapper";
import "swiper/css";
import styles from "./InfoCarousel.module.css";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import Cta from "../Cta";
import { NODE_PARAGRAPH, render } from "storyblok-rich-text-react-renderer";

interface InfoCarouselProps {
  blok: sbBlok;
}

const InfoCarousel = ({ blok }: InfoCarouselProps) => {
  const { windowSize } = useWindowSize();

  return (
    <GridWrapper>
      <GridElement>
        <Swiper
          {...storyblokEditable(blok)}
          className={styles.swiper}
          modules={[Navigation, Pagination]}
          spaceBetween={50}
          slidesPerView="auto"
          navigation={{
            nextEl: `.${styles.arrowRight}`,
            prevEl: `.${styles.arrowLeft}`,
          }}
          grabCursor
          centeredSlides
          pagination={{ clickable: true }}
        >
          {blok.content.map((i: sbBlok) => {
            return (
              <SwiperSlide key={i._uid} className={styles.swiperSlide}>
                <div className={styles.slideMedia}>
                  <Image
                    src={
                      windowSize.width < 961 && i.image_mobile?.filename
                        ? i.image_mobile.filename
                        : i.image.filename
                    }
                    alt={i.image.alt}
                    layout="fill"
                    objectFit="cover"
                  />
                </div>
                <div className={styles.slideContent}>
                  <h2>{i.title}</h2>
                  {i.content_text &&
                    render(i.content_text, {
                      nodeResolvers: {
                        [NODE_PARAGRAPH]: (children) => <p>{children}</p>,
                      },
                    })}
                  {i.content.map((c: sbBlok) => {
                    return <p key={c._uid}>{c.content}</p>;
                  })}
                  {(i.cta_link?.url?.length > 0 ||
                    i.cta_link?.story?.slug?.length > 0) && (
                    <Cta
                      className={styles.ctaButtonMargin}
                      name={
                        i.cta_link_name.length > 0
                          ? i.cta_link_name
                          : i.cta_link?.story?.name
                      }
                      href={i.cta_link?.url}
                      fullSlug={i.cta_link?.story?.full_slug}
                    />
                  )}
                </div>
              </SwiperSlide>
            );
          })}
          <div className={`${styles.arrowLeft} ${styles.arrow}`}>
            <ArrowBackIosNewOutlinedIcon />
          </div>
          <div className={`${styles.arrowRight} ${styles.arrow}`}>
            <ArrowForwardIosOutlinedIcon />
          </div>
        </Swiper>
      </GridElement>
    </GridWrapper>
  );
};

export default InfoCarousel;
