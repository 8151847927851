import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import { sbBlok } from "../../types/sb-types";
import GridElement from "../GridElement";
import GridWrapper from "../GridWrapper";
import PrizePoolItem from "../PrizePoolItem/PrizePoolItem";
import bgArrows from "../../public/bg_graphics/arrows_in.svg";
import styles from "./PrizePoolTable.module.css";

const PRIZE_DISTRIBUTION = {
  round_1: 126600,
  round_2: 150600,
  round_3: 150500,
  round_4: 148200,
  round_5: 150600,
};

const TOTAL_PLAYERS = {
  round_1: 267,
  round_2: 506,
  round_3: 505,
  round_4: 482,
  round_5: 506,
};

const PrizePoolTable = ({ blok }: sbBlok) => {
  const [selectedRound, setSelectedRound] = useState("round_1");
  return (
    <section className={styles.prizePoolTableWrapper}>
      <GridWrapper
        className={styles.bgGraphic}
        padding_top={blok.spacing_top?.length > 0 && blok.spacing_top[0].option}
        padding_bottom={
          blok.spacing_bottom?.length > 0 && blok.spacing_bottom[0].option
        }
      >
        <GridElement
          gridLayout={[
            [961, 9, 8],
            [1401, 17, 16],
          ]}
        >
          <div className={styles.bgGraphicImage}>
            <Image
              src={bgArrows}
              alt="background arrows"
              layout="fill"
              objectFit="contain"
            />
          </div>
        </GridElement>
      </GridWrapper>
      <GridWrapper className={styles.prizePoolTableGrid}>
        <GridElement>
          <div {...storyblokEditable(blok)} className={styles.prizePoolTable}>
            <ul className={styles.prizePoolTableTabs}>
              {blok.content.map((c: sbBlok) => (
                <li
                  key={c._uid}
                  className={`${styles.prizePoolTableTab}${
                    selectedRound === `round_${c.id}`
                      ? " " + styles.prizePoolTableTabActive
                      : ""
                  }`}
                  onClick={() => setSelectedRound(`round_${c.id}`)}
                >
                  {c.title}
                </li>
              ))}
            </ul>
            <div className={styles.prizePoolContainer}>
              {blok.content.map((c: sbBlok) => (
                <PrizePoolItem
                  content={c}
                  key={c._uid}
                  roundParticipants={TOTAL_PLAYERS[`round_${c.id}` as keyof {}]}
                  roundPrizeTotal={parseInt(
                    PRIZE_DISTRIBUTION[`round_${c.id}` as keyof {}],
                    10
                  )}
                  inView={selectedRound === `round_${c.id}`}
                  sidebets={c.sidebets}
                />
              ))}
            </div>
          </div>
        </GridElement>
        <GridElement>
          <div style={{ textAlign: "center", paddingBottom: "6rem" }}>
            <em style={{ lineHeight: 2 }}>
              See the full{" "}
              <Link href={"/prize-list"}>
                <a style={{ color: "var(--tm-c-brand)" }}>prize list</a>
              </Link>
              , the prize distribution does not incl. tie rule, see{" "}
              <Link href={"/faq-rules"}>
                <a style={{ color: "var(--tm-c-brand)" }}>Rules &amp; FAQ</a>
              </Link>{" "}
              for more information.
            </em>
          </div>
        </GridElement>
      </GridWrapper>
    </section>
  );
};

export default PrizePoolTable;
