import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import CountryFlag from "react-country-flag";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { sbBlok } from "../../types/sb-types";
import GridElement from "../GridElement";
import GridWrapper from "../GridWrapper";
import "swiper/css";
import { motion } from "framer-motion";
import styles from "./PlayerCarousel.module.css";

const PlayerCarousel = ({ blok }: sbBlok) => {
  return (
    <GridWrapper>
      <GridElement>
        <Swiper
          {...storyblokEditable(blok)}
          className={styles.swiperWrapper}
          spaceBetween={10}
          slidesPerView="auto"
          centeredSlides
          pagination={{ clickable: true }}
          modules={[Pagination]}
          cssMode
          grabCursor
          speed={100}
          allowTouchMove={false}
          onClick={(swiper, e) => {
            e.stopPropagation();
            swiper.clickedIndex !== undefined &&
              swiper.slideTo(swiper.clickedIndex, 100);
          }}
        >
          {blok?.player_carousel_items?.map((i: sbBlok, index: number) => {
            return (
              <SwiperSlide key={i._uid} className={styles.playerSlide}>
                {({ isActive }) => (
                  <motion.div
                    initial={{ opacity: 0.4, scale: 0.9 }}
                    animate={{
                      opacity: isActive ? 1 : 0.4,
                      scale: isActive ? 1 : 0.9,
                    }}
                    transition={{
                      ease: "easeInOut",
                    }}
                  >
                    <div className={styles.playerSlideMedia}>
                      <Image
                        src={i.image.filename}
                        alt={i.image.alt}
                        layout="fill"
                        objectFit="cover"
                      />
                    </div>
                    <div className={styles.playerSlideContent}>
                      {i?.preview_text ? (
                        <>
                          <div className={styles.seasonNumber}>
                            Season {i.season_number}
                          </div>
                          <div className={styles.playerTitle}>
                            {i?.preview_text}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={styles.seasonNumber}>
                            Season {i.season_number}
                          </div>
                          <div className={styles.playerName}>{i?.name}</div>
                          <div className={styles.playerCountry}>
                            <CountryFlag
                              countryCode={i?.country_code}
                              style={{ fontSize: "3rem" }}
                              svg
                            />
                            {i?.country}
                          </div>
                          <div className={styles.playerResults}>
                            <div className={styles.playerResultsInfo}>
                              <div>Earnings</div>
                              <div>{i?.earnings}</div>
                            </div>
                            <div className={styles.playerResultsInfo}>
                              <div>Hcp</div>
                              <div>{i?.hcp}</div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </motion.div>
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </GridElement>
    </GridWrapper>
  );
};

export default PlayerCarousel;
