import React, { useState, useEffect } from "react";
import styles from "./Faq.module.css";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { sbBlok } from "../../types/sb-types";
import { motion } from "framer-motion";
import {
  NODE_LI,
  NODE_OL,
  NODE_PARAGRAPH,
  NODE_UL,
  MARK_LINK,
  render,
} from "storyblok-rich-text-react-renderer";

const FaqItem = ({ blok }: sbBlok) => {
  const [isOpen, setIsOpen] = useState(false);

  const heightVariants = {
    initial: {
      height: 0,
    },
    open: {
      height: "auto",
    },
  };

  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, []);
  if (isFirstRender) return <div />;

  return (
    <div
      key={blok._uid}
      className={styles.faqCollapsibleContainer}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div>
        <div className={styles.faqCollapsibleHeader}>
          <div>{blok.title}</div>
          <motion.div
            animate={{
              rotate: isOpen ? -45 : 0,
            }}
            transition={{
              ease: "easeInOut",
            }}
          >
            <AddRoundedIcon className={styles.faqHeaderIcon} />
          </motion.div>
        </div>
      </div>

      <motion.div
        variants={heightVariants}
        animate={isOpen ? "open" : "initial"}
        className={styles.faqCollapsibleContent}
      >
        <div>
          {render(blok?.content, {
            nodeResolvers: {
              [NODE_PARAGRAPH]: (children) => <p>{children}</p>,
              [NODE_UL]: (children) => <ul>{children}</ul>,
              [NODE_OL]: (children) => <ol>{children}</ol>,
              [NODE_LI]: (children) => <li>{children}</li>,
            },
            markResolvers: {
              [MARK_LINK]: (children, props) => {
                return (
                  <a
                    href={props?.href}
                    target={props?.target}
                    className={styles.faqLink}
                  >
                    {children}
                  </a>
                );
              },
            },
          })}
        </div>
      </motion.div>
    </div>
  );
};

export default FaqItem;
