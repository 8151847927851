export const TournamentLongestDrive = `query getInroundLongestDriveLeaderboard(
  $holeNumber: Int!,
  $roundId: ID!,
  $publishedTournamentId: ID!,
  $skip: Int,
  $take: Int,
  $playerIds: [ID!]!,
  $gender: Gender,
  $nationality: [String!],
  $playNow: Boolean,
  $searchText: String
) {
  node(id: $publishedTournamentId) {
    ... on CourseTournament {
      unit
      tournamentState
      embeddedGameLeaderboard(
        holeNumber: $holeNumber
        roundId: $roundId
        gender: $gender
      ) {
        ... on LongestDrive {
          records(skip: $skip, take: $take, nationality: $nationality, playNow: $playNow, gender: $gender, searchText: $searchText) {
            items {
              playername
              id
              nationality
              score {
                driveDistance
                pos
                posLabel
              }
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            totalCount
          }
          selectedPlayers(ids: $playerIds) {
            playername
            nationality
            score {
              driveDistance
              posLabel
              pos
            }
          }
        }
      }
    }
  }
}`;
