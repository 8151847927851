import { createElement, ReactNode } from "react";
import { styled, setup } from "goober";
import { shouldForwardProp } from "goober/should-forward-prop";

setup(
  createElement,
  undefined,
  undefined,
  // This package accepts a `filter` function. If you return false that prop
  // won't be included in the forwarded props.
  shouldForwardProp((prop) => {
    return prop !== "mQueries";
  })
);

interface GridElementProps {
  gridLayout?: number[][];
  children: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

// gridColumn interface
// [
// ..[
// .. .. 961: min-width breakpoint,
// .. .. 1: grid column,
// .. .. 10: grid column span,
// .. .. 1: grid row (optional)
// ..]: Grid Layout Values]

const StyledGridElement = styled("div")<{ columns?: string; rows?: string }>`
  position: relative;
  ${(props) => props.columns}
`;

const GridElement = ({
  gridLayout,
  children,
  className,
  style,
}: GridElementProps) => {
  const columnMediaQueries = gridLayout
    ?.map(
      (b) => `@media only screen and (min-width: ${b[0]}px) {
    grid-column: ${b[1]} / span ${b[2]};
    grid-row: ${b[3] || 1};
  }`
    )
    .join("; ");

  return (
    <StyledGridElement
      columns={columnMediaQueries}
      className={className}
      style={style}
    >
      {children}
    </StyledGridElement>
  );
};

export default GridElement;
