import Link from "next/link";
import styles from "./Cta.module.css";

type Variant = "primary" | "outlined" | "secondary";
interface ExternalLinkProps {
  fullSlug?: string;
  className?: string;
  name: string;
  style?: React.CSSProperties;
  variant?: Variant;
}

interface InternalLinkProps {
  href?: string;
  className?: string;
  name: string;
  style?: React.CSSProperties;
  variant?: Variant;
}

interface ButtonProps {
  disabled?: boolean;
  className?: string;
  name: string;
  style?: React.CSSProperties;
  variant?: Variant;
  onClick?: (e: any) => void;
}

type CtaProps = ExternalLinkProps & InternalLinkProps & ButtonProps;

const Cta = ({
  disabled,
  fullSlug,
  href,
  name,
  className,
  variant = "primary",
  style,
  onClick,
}: CtaProps) => {
  const rootClasses = [styles.cta, styles.ctaButton, styles[variant]];

  if (className) {
    rootClasses.push(className);
  }

  const renderRootClasses = rootClasses.join(" ");

  if (href) {
    return (
      <a
        className={renderRootClasses}
        target="_blank"
        rel="noopener noreferrer"
        href={href}
        style={style}
      >
        <span>{name}</span>
      </a>
    );
  }

  if (onClick) {
    return (
      <button
        className={renderRootClasses}
        style={style}
        disabled={disabled}
        onClick={onClick}
      >
        {name}
      </button>
    );
  }

  return (
    <Link href={`/${fullSlug}`}>
      <a className={renderRootClasses} style={style} onClick={onClick}>
        <span>{name}</span>
      </a>
    </Link>
  );
};

export default Cta;
