export const TournamentBirdieStreak = `query getGameSummaryLeaderboard(
  $roundId: ID!,
  $publishedTournamentId: ID!,
  $gender: Gender,
  $kind: OtherLeaderboardsKind!,
  $skip: Int,
  $take: Int,
  $nationality: [String!],
  $playNow: Boolean,
  $searchText: String
) {
  node(id: $publishedTournamentId) {
    ... on CourseTournament {
      otherLeaderboards(kind: $kind
        roundId: $roundId
        gender: $gender
        ) {
          records(nationality: $nationality, playNow: $playNow, gender: $gender, 
              searchText: $searchText, skip: $skip, take: $take) {
            items {
              playerId
              nationality
              playername
              result {
                pos
                score
              }
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            totalCount
          }
        }
    }
  }
}`;
