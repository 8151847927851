import Image from "next/image";
import { sbBlok } from "../../types/sb-types";
import GridElement from "../GridElement";
import GridWrapper from "../GridWrapper";
import styles from "./TextWithMediaSmall.module.css";

interface TextWithMediaSmallProps {
  blok: sbBlok;
}

const TextWithMediaSmall = ({ blok }: TextWithMediaSmallProps) => {
  return (
    <GridWrapper style={{ paddingTop: "4rem", paddingBottom: "4rem" }}>
      <GridElement
        gridLayout={[
          [601, 1, 2, 1],
          [961, 3, 6, 1],
          [1401, 9, 8, 1],
        ]}
      >
        <div className={styles.imageWrapper}>
          <Image
            src={blok.image.filename}
            alt={blok.image.alt}
            layout="responsive"
            width={400}
            height={460}
            objectFit="cover"
          />
        </div>
      </GridElement>
      <GridElement
        gridLayout={[
          [601, 3, 2, 1],
          [961, 10, 5, 1],
          [1201, 9, 5, 1],
          [1401, 18, 8, 1],
        ]}
      >
        <div className={styles.contentWrapper}>
          <h2>Klaus Eldrup-Jørgensen</h2>
          <p>TrackMan Co-Founder and CEO</p>
        </div>
      </GridElement>
    </GridWrapper>
  );
};

export default TextWithMediaSmall;
