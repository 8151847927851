import { sbBlok } from "../../types/sb-types";
import BlogPost from "./BlogPost";
import GridElement from "../GridElement";
import GridWrapper from "../GridWrapper";
import styles from "./BlogPostsOverview.module.css";
import Cta from "../Cta/Cta";
import Link from "next/link";
import { motion } from "framer-motion";
import { useWindowSize } from "../../hooks/useWindowSize";

interface BlogPostsOverviewProps {
  stories: sbBlok[];
  blok: sbBlok;
  isFirstPostLarge?: boolean;
}

const BlogPostsOverview = ({
  blok,
  stories,
  isFirstPostLarge,
}: BlogPostsOverviewProps) => {
  const { isDesktop, windowSize } = useWindowSize();

  const smallBlogPosts = isFirstPostLarge
    ? stories?.slice(1)
    : windowSize.width > 809 && windowSize.width <= 960
    ? stories?.slice(0, 2)
    : stories?.slice(0, 3);

  return (
    <GridWrapper
      padding_top={blok?.spacing_top?.length > 0 && blok.spacing_top[0].option}
      padding_bottom={
        blok?.spacing_bottom?.length > 0 && blok.spacing_bottom[0].option
      }
      gridClassName={styles.blogPostsGridWrapper}
    >
      <GridElement gridLayout={[[1401, 4, 26]]}>
        <h1 className={styles.blogPostsTitle}>Latest</h1>
        {isFirstPostLarge && (
          <BlogPost
            isLarge
            ctaType="button"
            blok={{
              _uid: stories[0].content._uid,
              component: stories[0].content.component,
              image: stories[0].content.preview_image,
              meta_title: stories[0].content.preview_info_title,
              title: stories[0].content.preview_title,
              intro: stories[0].content.preview_intro,
              cta_link: blok?.cta_link
                ? [
                    {
                      name: blok.cta_link?.[0]?.name,
                      link: {
                        linktype: blok.cta_link?.[0]?.link?.linktype,
                        story: {
                          url: blok.cta_link?.[0]?.link?.story?.url,
                        },
                      },
                    },
                  ]
                : undefined,
            }}
          />
        )}
        {smallBlogPosts?.length > 0 && (
          <motion.section
            className={styles.blogPostsSmall}
            viewport={{ once: false }}
            initial={{
              opacity: isDesktop ? 1 : 0.3,
              transform: isDesktop ? "translate(0, 0)" : "translate(0, 6rem)",
            }}
            whileInView={{
              opacity: 1,
              transform: "translate(0, 0)",
            }}
            transition={{
              ease: "linear",
              duration: 0.8,
            }}
          >
            {smallBlogPosts.map((blogPost: any) => (
              <Link key={blogPost.content._uid} href={blogPost.full_slug}>
                <a className={styles.blogPostsSmallLink}>
                  <BlogPost
                    blok={{
                      _uid: blogPost.content._uid,
                      component: blogPost.content.component,
                      image: blogPost.content.preview_image,
                      meta_title: blogPost.content.preview_info_title,
                      title: blogPost.content.preview_title,
                      intro: blogPost.content.preview_intro,
                    }}
                  />
                </a>
              </Link>
            ))}
          </motion.section>
        )}
        {!isFirstPostLarge && blok?.cta_link && (
          <motion.div
            viewport={{ once: false }}
            initial={{
              transform: isDesktop ? "translate(0, 0)" : "translate(0, 6rem)",
            }}
            whileInView={{
              transform: "translate(0, 0)",
            }}
            transition={{
              ease: "linear",
              duration: 0.8,
            }}
          >
            <Cta
              className={styles.blogPostsMargin}
              href={blok.cta_link[0]?.link?.url}
              fullSlug={blok.cta_link[0]?.link?.story?.full_slug}
              name={blok.cta_link[0]?.name}
            />
          </motion.div>
        )}
      </GridElement>
    </GridWrapper>
  );
};

export default BlogPostsOverview;
