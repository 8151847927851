export const TournamentClosestToPinAM = `query getInRoundClosestToPinLeaderboard($holeNumber: Int!, $roundId: ID!, $publishedTournamentId: ID!, $playerIds: [ID!]!, $gender: Gender, $skip: Int, $take: Int) {
  node(id: $publishedTournamentId) {
    ...InRoundClosestFragment
  }
}
    fragment InRoundClosestFragment on CourseTournament {
  unit
  tournamentState
  embeddedGameLeaderboard(
    holeNumber: $holeNumber
    roundId: $roundId
    gender: $gender
  ) {
    ... on ClosestToPin {
      records(skip: $skip, take: $take) {
        items {
          playername
          nationality
          id
          gender
          score {
            distanceToPin
            pos
            posLabel
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
      }
      selectedPlayers(ids: $playerIds) {
        playername
        nationality
        score {
          distanceToPin
          posLabel
          pos
        }
      }
    }
  }
}`;
